const changePinTexture = (overwriteChannels, textureName) => {
  return new Promise((res) => {
    const texture = (window.api.textures.filter((t) => t.name === textureName) || [])[0]
    if (!texture) return res()

    const defaultMaterial = { ...window.api._getMaterialByName('pins_mat') }
    defaultMaterial.channels = overwriteChannels(defaultMaterial.channels, texture.uid)

    return window.api.setMaterial(defaultMaterial, res)
  })
}

export { changePinTexture }
