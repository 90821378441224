import { srgbToLinear, hexToRgb } from './colorTools.helper'

const changeCupholder = (overwriteChannels, hex, _api) => {
  const api = _api || window.api
  return new Promise((res) => {
    if (!overwriteChannels) return res()

    const defaultMaterial = { ...api._getMaterialByName('cupholders_mat') }
    const linearColor = srgbToLinear(hexToRgb(hex))
    defaultMaterial.channels = overwriteChannels(defaultMaterial.channels, linearColor)

    return api.setMaterial(defaultMaterial, res)
  })
}

export { changeCupholder }
