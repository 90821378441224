import styled from 'styled-components'

export const Container = styled.div`
  transform: translate3d(0, 0, 0);
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow: auto;
  padding: 32px 0;
  box-sizing: border-box;

  .logoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    .logo {
      height: 100px;
      width: 160px;
      background-size: contain;
      background-image: url(${require('../../img/logomark.png')});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .message {
    max-width: 530px;
    padding: 0 8px;
    a {
      color: #000;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .layout {
    height: 500px;
    max-height: 70vh;
    margin: 24px 8px 32px 8px;
    width: auto;
    background-size: contain;
    background-image: url(${require('../../img/home_theater_layout.jpg')});
    background-repeat: no-repeat;
    background-position: center;
  }
`

export const style = {}

export default style
