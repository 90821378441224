const armrests = ['arms_B', 'arms_C', 'arms_D', 'arms_F', 'arms_J', 'arms_L', 'arms_S', 'arms_I', 'arms_N'].map(
  (node) => ({
    node,
    buttonImage: require(`../img/${node}.png`),
    buttonName: node.split('_')[1],
    containsWood: ['arms_B', 'arms_D', 'arms_F', 'arms_J', 'arms_S'].includes(node),
  })
)

export default armrests
