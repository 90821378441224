import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  height: 100vh;
  height: calc(100vh - 70px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(239, 239, 239, 1);
  z-index: 1000;
  padding: 0 2vw;
  box-sizing: border-box;
  transition: visibility 0s 0.5s, opacity 0.5s 0s linear;

  &.loaded {
    opacity: 0;
    pointer-events: 'none';
    visibility: hidden;
  }
  &.loading {
    opacity: 1;
    pointer-events: 'all';
    visibility: visible;
  }

  & > div {
    width: 100%;
    text-align: center;
  }
  .buttonWrapper {
    margin: 0 auto;
    position: relative;
    height: 100px;
    max-width: 620px;

    .message {
      font-weight: 700;
      line-height: 35px;
      margin-top: 35px;
      position: relative;
      &:before {
        content: '';
        top: -20px;
        position: absolute;
        height: 3px;
        background-color: #b99777;
        width: 100%;
        left: 50%;
        transform: translate(-50%);
        animation: pullTheLine 3.5s ease-in-out;
      }
    }
    .shown {
      opacity: 1;
      pointer-events: all;
    }
    .hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
  .logoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30%;
    min-height: 150px;
    .logo {
      height: 100px;
      width: 160px;
      animation: logo-breath 2s infinite;
      background-size: contain;
    }
  }
  .chairImage,
  .logo {
    background-image: url(${require('../../img/logomark.png')});
    background-repeat: no-repeat;
    background-position: center;
  }
  .chairImage {
    display: block;
    position: relative;
    background-size: 50px auto;
    width: 100%;
    padding-bottom: 100%;
    background-color: #efefef;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 90%;
    }
  }
  .morphingWrapper {
    height: 60%;
    position: relative;
    height: 500px;
    width: 500px;
    max-width: 100%;
    max-height: 100%;
    .chairWrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0 30%;

      height: 100%;
      width: 100%;
      max-width: 48vh;

      &.chair1 {
        /* left: 30%; */
        animation: pulse-inverse 0.8s infinite ease-out;
      }
      &.chair2 {
        /* left: 65%; */
        animation: pulse 0.8s infinite ease-out;
        /* opacity: 0 !important; */
      }
    }
  }
  .title {
    color: #000;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: none;
  }
  .content {
    margin-bottom: 0;
    font-style: italic;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
      /* z-index: 1; */
    }
    50% {
      opacity: 0;
      /* z-index: 2; */
    }
    100% {
      opacity: 1;
      /* z-index: 2; */
    }
  }

  @keyframes pulse-inverse {
    0% {
      opacity: 1;
      /* z-index: 2; */
    }
    50% {
      opacity: 1;
      /* z-index: 1; */
    }
    100% {
      opacity: 1;
      /* z-index: 1; */
    }
  }

  @keyframes pullTheLine {
    0% {
      width: 0%;
    }
    5% {
      width: 8%;
    }
    10% {
      width: 12%;
    }
    15% {
      width: 13%;
    }
    20% {
      width: 20%;
    }
    25% {
      width: 26%;
    }
    30% {
      width: 40%;
    }
    35% {
      width: 49%;
    }
    40% {
      width: 60%;
    }
    45% {
      width: 70%;
    }
    55% {
      width: 75%;
    }
    80% {
      width: 80%;
    }
    90% {
      width: 85%;
    }
    95% {
      width: 90%;
    }
    100% {
      width: 100%;
    }
  }

  @media only screen and (max-width: 772px) {
    .buttonWrapper .message {
      line-height: 20px;
      font-size: 14px;
    }
    .logoWrapper {
      height: 40%;
    }
    .morphingWrapper {
      height: 60%;
    }
  }
`

export const style = {}

export default style
