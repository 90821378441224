import Axios from 'axios';

// const api_endpoint = 'http://localhost:2888/sendmail'; // LOCAL
// const api_endpoint = 'https://api.tapmod.studio/sendmail'; // Tapmod API
const api_endpoint = 'https://api.tapmod.studio/.netlify/functions/sendgrid'; // Tapmod LAMBDA API

// TODO: переделать костыль с cb
var Email = {
  send: function(message, cb) {
    Axios.post(api_endpoint, message)
      .then(function(response) {
        return cb('OK', 'success');
      })
      .catch(function(error) {
        console.error(error);
        return cb(error, 'error');
      });
  }
};

export default Email;
