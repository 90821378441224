const cupholders = [
  {
    name: 'Black',
    buttonImg: require('../img/cup_black.png'),
    hex: '#242424',
    overwriteChannels: (channels, linearColor) => {
      channels.AlbedoPBR.color = linearColor
      channels.MetalnessPBR.enable = true
      channels.MetalnessPBR.factor = 0
      channels.RoughnessPBR.enable = true
      channels.RoughnessPBR.factor = 0.07
      return channels
    },
  },
  {
    name: 'Gun Metal',
    buttonImg: require('../img/cup_gun_metal.png'),
    hex: '#5F5E5A',
    overwriteChannels: (channels, linearColor) => {
      channels.AlbedoPBR.color = linearColor
      channels.MetalnessPBR.enable = true
      channels.MetalnessPBR.factor = 1.0
      channels.RoughnessPBR.enable = true
      channels.RoughnessPBR.factor = 0.24
      return channels
    },
  },
  {
    name: 'Copper',
    buttonImg: require('../img/cup_copper.png'),
    hex: '#6A5E4B',
    overwriteChannels: (channels, linearColor) => {
      channels.AlbedoPBR.color = linearColor
      channels.MetalnessPBR.enable = true
      channels.MetalnessPBR.factor = 1.0
      channels.RoughnessPBR.enable = true
      channels.RoughnessPBR.factor = 0.24
      return channels
    },
  },
  {
    name: 'Silver',
    buttonImg: require('../img/cup_silver.png'),
    hex: '#B6BBBD',
    overwriteChannels: (channels, linearColor) => {
      channels.AlbedoPBR.color = linearColor
      channels.MetalnessPBR.enable = true
      channels.MetalnessPBR.factor = 1.0
      channels.RoughnessPBR.enable = true
      channels.RoughnessPBR.factor = 0.4
      return channels
    },
  },
]

export default cupholders
