const toProperCase = (word) => {
  var words = word.split('_')
  var results = []
  for (var i = 0; i < words.length; i++) {
    var letter = words[i].charAt(0).toUpperCase()
    results.push(letter + words[i].slice(1))
  }
  return results.join(' ')
}

const data = {
  l1: ['single', `35″ (89cm)`],
  l2: ['loveseat', `58″ (147cm)`],
  l3: ['straight_row_of_two', `64″ (163cm)`],
  l4: ['curved_row_of_two', `69″ (175cm)`],
  l5: ['three_seat_sofa', `81″ (206cm)`],
  l6: ['straight_row_of_three', `93″ (236cm)`],
  l7: ['curved_row_of_three', `103″ (262cm)`],
  l8: ['double_loveseat', `110″ (320cm)`],
  l9: ['curved_double_loveseat', `115″ (292cm)`],
  l10: ['straight_flanked_loveseat', `116″ (295cm)`],
  l12: ['curved_flanked_loveseat', `126″ (320cm)`],
  l11: ['straight_row_of_four', `122″ (310cm)`],
  l13: ['curved_row_of_four', `137″ (348cm)`],
  l14: ['straight_row_of_five', `151″ (384cm)`],
  l15: ['curved_row_of_five', `171″ (434cm)`],
  l16: ['captains_chair_double_loveseat', `156" , 396cm`],
}

const layouts = Object.keys(data).map((k) => {
  const layout = {
    buttonImage: require(`../img/${k}.png`),
    buttonName: toProperCase(data[k][0]),
    node: data[k][0],
    width: data[k][1],
  }
  return layout
})
// console.log(layouts.map((l) => l.buttonName).join(', '))

export default layouts
