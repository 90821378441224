import React, { Component } from "react";

import Modal from "components/Modal";
import logo from "img/elite_hts_logo.png";
import { style, Container } from "./Header.style";

class Header extends Component {
  state = {
    isModalOpen: false
  };

  toggleModal = (show = !this.state.isModalOpen) => {
    this.setState({ isModalOpen: show });
  };
  render() {
    const { isModalOpen } = this.state;
    return (
      <React.Fragment>
        <Container>
          <div id="menuToggle" className="mobileBurger">
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul id="menu">
              <a
                // target="_blank" rel="noopener noreferrer"
                href="https://elitehts.com"
              >
                <li>{`<< Back To main menu`}</li>
              </a>
              <a onClick={() => this.toggleModal(true)}>
                <li>Contact</li>
              </a>
            </ul>
          </div>
          <div className="logo">
            <a
              // target="_blank" rel="noopener noreferrer"
              href="https://elitehts.com"
            >
              <img src={logo} alt="" />
            </a>
          </div>
          <div className="sider">
            <div className="menu-left">
              <div className="item">
                <a
                  // target="_blank" rel="noopener noreferrer"
                  href="https://elitehts.com"
                >{`<< Back To main menu`}</a>
              </div>
              <div className="item">
                <a onClick={() => this.toggleModal(true)}>Contact</a>
              </div>
            </div>
          </div>
        </Container>
        <div
          className="modalWrapper"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100vw",
            pointerEvents: isModalOpen ? "all" : "none",
            zIndex: 100
          }}
        >
          <Modal
            title="Contact Us"
            isVisible={isModalOpen}
            closeModal={() => this.toggleModal(false)}
            slimContent={true}
          >
            <div
              className="contactText"
              style={{
                paddingTop: "30px"
              }}
            >
              For assistance please email{" "}
              <a href="mailto:sales@elitehts.com">
                <b>sales@elitehts.com</b>
              </a>
              , or call our concierge/sales team at{" "}
              <a href="tel:6045758310">
                <b>604-575-8310</b>
              </a>{" "}
              (8am to 4pm PST). <br />
              <br />
              International callers please call{" "}
              <a href="tel:01414454195">
                <b>0141 445 4195</b>
              </a>{" "}
              (8:30am to 4:30pm GMT/UTC)
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default Header;
