import React from 'react'
// import 'inobounce'

import Configurator from 'containers/Configurator'
import Header from 'components/Header'
import { armrests, backrests, woodtrims, woodFinishes, leathers, cupholders, stitches, layouts, pins } from 'mappers'

import './App.css'

function parseQueryToConfigs() {
  const parameters = new URLSearchParams(window.location.search)
  const configs = {}
  if (parameters.has('export')) {
    configs['forExport'] = parameters.get('export')
  }
  if (parameters.has('home_theater_seat_armrest')) {
    configs['arm'] = armrests.filter((el) => el.buttonName === parameters.get('home_theater_seat_armrest'))[0]
  }
  if (parameters.has('home_theater_chair_backrest')) {
    configs['seat'] = backrests.filter((el) => el.buttonName === parameters.get('home_theater_chair_backrest'))[0]
  }
  if (parameters.has('wood_trim')) {
    configs['wood'] = woodtrims.filter((el) => el.name === parameters.get('wood_trim'))[0]
  }
  if (parameters.has('wood_finish')) {
    configs['finish'] = woodFinishes.filter((el) => el.class === parameters.get('wood_finish'))[0]
  }
  if (parameters.has('metal_finish')) {
    configs['pin'] = pins.filter((el) => el.buttonName === parameters.get('metal_finish'))[0]
  }
  if (parameters.has('leather_material') && parameters.has('leather_color')) {
    configs['leather'] = leathers
      .flat()
      .filter(
        (el) =>
          el.leatherName === parameters.get('leather_material') && el.colorName === parameters.get('leather_color')
      )[0]
  }
  if (parameters.has('cupholder')) {
    configs['cupholder'] = cupholders.filter((el) => el.name === parameters.get('cupholder'))[0]
  }
  if (parameters.has('stitch_color')) {
    configs['stitch'] = stitches.filter((el) => el.name === parameters.get('stitch_color'))[0]
  }
  if (parameters.has('diamond')) {
    configs['diamond'] = parameters.get('diamond') === '1'
  }
  if (parameters.has('piping_material') && parameters.has('piping_color')) {
    configs['pipingMaterial'] = leathers
      .flat()
      .filter(
        (el) => el.leatherName === parameters.get('piping_material') && el.colorName === parameters.get('piping_color')
      )[0]
    configs['piping'] = true
  }
  if (parameters.has('layout_front')) {
    configs['layoutFront'] = layouts.filter((el) => el.buttonName === parameters.get('layout_front'))[0]
  }
  if (parameters.has('layout_back')) {
    configs['layoutBack'] = layouts.filter((el) => el.buttonName === parameters.get('layout_back'))[0]
  }
  return configs
}

function App() {
  return (
    <div
      // style={{
      //   height: window.innerHeight,
      //   WebkitOverflowScrolling: 'touch',
      //   overflowY: 'scroll',
      // }}
      className="App"
    >
      <Header />
      <div id="configuratorWrapper" className="configuratorWrapper">
        <Configurator {...parseQueryToConfigs()} />
      </div>
    </div>
  )
}

export default App
