const hideAllSeatNodes = () => {
  // console.log(window.api.nodes)
  const nodesToHide = ['seat_3_5_6_9', 'seat_1', 'seat_2', 'seat_3', 'seat_5', 'seat_6', 'seat_9']
  const promisses = []
  nodesToHide.forEach((node) => {
    promisses.push(window.api.node_hide(node))
  })
  return Promise.all(promisses)
}

const showOneSeat = (nodeName) => {
  return hideAllSeatNodes().then(() => {
    if (nodeName !== 'seat_1' && nodeName !== 'seat_2') {
      window.api.node_show('seat_3_5_6_9')
    }
    return window.api.node_show(nodeName)
  })
}

export { showOneSeat }
