import React, { Component } from 'react'

import { Container } from './ConfirmationPage.style'

class ConfirmationPage extends Component {
  render() {
    return (
      <Container>
        <div className="logoWrapper">
          <div className="logo" />
          <div className="message">
            Thank you. Your quotation request has been successfully submitted.
            <br />
            Our current response time is less than 1 business day.
            <br /> <br />
            For immediate pricing requests, please call our concierge sales team at 604-575- 8310 (8am to 4pm PST).
            International residents may also call our UK office at 0141 445 4195 (8:30am to 4:30pm GMT/UTC)
            <br /> <br />
            If you are a dealer or trade professional, we also offer complimentary layout design services as shown in the
            example below. Simply send us your room dimensions and/or drawings and we’ll take it from there.
            <br /> <br />
            Our precise measurements including radius will allow you to plan your theater and build your platforms so that
            everything fits like a glove. We can also adjust the width and curvature of our seating to match your
            existing theater.
            <br /> <br />
            Feel free to email the details of your theater room to{' '}
            <a href="mailto:layouts@elitehts.com">
              <b>layouts@elitehts.com</b>
            </a>{' '}
            <br />
            Please mention your company name so that we can cross reference with the quotation request you have just
            submitted. Thank you.
          </div>
        </div>
        <div className="layout" />
      </Container>
    )
  }
}

ConfirmationPage.propTypes = {}

export default ConfirmationPage
