const pins = ['antique_brass', 'oxford_finish', 'bright_brass', 'french_natural', 'nickel'].map((p) => ({
  buttonName: p.split('_').map((w) => w.charAt(0).toUpperCase() + w.slice(1)).join(' '),
  buttonImage: require(`../img/pin_${p}.png`),
  textureName: `${p}.jpg`,
  overwriteChannels: (channels, textureID) => {
    channels.AlbedoPBR.texture = { ...(channels.AlbedoPBR.texture || {}), uid: textureID }

    return channels
  },
}))

export default pins
