import { srgbToLinear, hexToRgb } from './colorTools.helper'
const changeColor = (overwriteChannels, hex, _api, withDiamond = true, diamondActive = false) => {
  const api = _api || window.api
  return new Promise((res) => {
    if (!overwriteChannels) return res()
    const materials = ['leather_mat']
    if (withDiamond) {
      materials.push('diamond_mat')
    }
    return materials.forEach((material) => {
      const defaultMaterial = { ...api._getMaterialByName(material) }
      const linearColor = srgbToLinear(hexToRgb(hex))
      defaultMaterial.channels = overwriteChannels(
        defaultMaterial.channels,
        linearColor,
        withDiamond && (material === 'leather_mat' || !diamondActive)
      )

      const cb = material === 'leather_mat' ? res : undefined
      return api.setMaterial(defaultMaterial, cb)
    })
  })
}

export { changeColor }
