const changeGlossiness = (overwriteChannels) => {
  return new Promise((res) => {
    if (!overwriteChannels) return res()
    const defaultMaterial = { ...window.api._getMaterialByName('wood_mat') }
    defaultMaterial.channels = overwriteChannels(defaultMaterial.channels)

    return window.api.setMaterial(defaultMaterial, res)
  })
}

export { changeGlossiness }
