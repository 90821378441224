let defaultTexture = null

const show = () => {
  return new Promise((res) => {
    const mat = window.api._getMaterialByName('diamond_mat')
    defaultTexture = defaultTexture || { ...mat.channels.NormalMap.texture }
    const normalTexture = (window.api.textures.filter((t) => t.name === 'diamond_normal.jpg') || [])[0]
    if (mat.channels.NormalMap.texture && normalTexture) {
      mat.channels.NormalMap.texture.uid = normalTexture.uid
      mat.channels.NormalMap.texture.texCoordUnit = 6
    }
    window.api.setMaterial(mat, res)
  })
}
const hide = () => {
  return new Promise((res) => {
    const mat = window.api._getMaterialByName('diamond_mat')
    const leatheMat = window.api._getMaterialByName('leather_mat')

    mat.channels.NormalMap.texture = { ...leatheMat.channels.NormalMap.texture }
    // mat.channels.NormalMap.texture = { ...defaultTexture }
    window.api.setMaterial(mat, res)
  })
}

export { show, hide }
