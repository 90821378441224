import styled from 'styled-components'

export const Container = styled.div`
  transform: translate3d(0, 0, 0);
  position: fixed;
  &,
  .contentWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    &.disabled {
      transform: translate3d(0, 0, 0);
      position: fixed;
      pointer-events: none;
      overflow: hidden;
    }
    iframe,
    .button,
    .panel,
    .panel * {
      pointer-events: all;
    }
    .loadingOveflow {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #ebebeb;
      background-image: url(${require('./tempBackground.jpg')});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      .spinner {
        animation: rotate 2s linear infinite;
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -65px 0 0 -25px;
        width: 40px;
        height: 40px;

        & .path {
          stroke: #eee;
          stroke-linecap: round;
          animation: dash 1.5s ease-in-out infinite;
        }
      }

      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes dash {
        0% {
          stroke-dasharray: 1, 150;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 90, 150;
          stroke-dashoffset: -35;
        }
        100% {
          stroke-dasharray: 90, 150;
          stroke-dashoffset: -124;
        }
      }
      .loadingMessage {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        color: #eee;
        @media only screen and (max-width: 772px) {
          font-size: 12px;
        }
      }
    }
  }
  * {
    transition: all 0.2s ease-in-out;
  }
  &.isLoaded {
    .centalPanel {
      opacity: 1;
      pointer-events: 'all';
    }
  }
  &.isLoading {
    .centalPanel {
      opacity: 0;
      transition: all 0.5s ease-in-out;
      pointer-events: 'none';
    }
  }
  .react-hint {
    font-size: 10px;
    left: 0 !important;
    &:after {
      display: none;
    }
    .react-hint__content {
      background: rgba(117, 101, 72, 0.8);
      color: #eaeaea;
      /* background-color: rgba(130, 130, 130, 0.6);
      color: #000; */
      border-radius: 0;
      font-weight: lighter;
      padding: 10px;
      max-width: 50vw;
      max-height: 11vw;
      overflow: auto;
    }
  }
  .check {
    cursor: pointer;
    position: relative;
    margin: 0 4px;
    width: 18px;
    height: 18px;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
    &:before {
      content: '';
      position: absolute;
      top: -15px;
      left: -15px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: rgba(34, 50, 84, 0.03);
      opacity: 0;
      transition: opacity 0.2s ease;
    }
    svg {
      position: relative;
      z-index: 1;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: #c8ccd4;
      stroke-width: 1.5;
      transform: translate3d(0, 0, 0);
      transition: all 0.2s ease;
      path {
        stroke-dasharray: 60;
        stroke-dashoffset: 0;
      }
      polyline {
        stroke-dasharray: 22;
        stroke-dashoffset: 66;
      }
    }
    &:hover:before {
      opacity: 1;
    }
    &:hover svg {
      stroke: #333;
    }
  }
  #cbx:checked + .check svg,
  [id^='formCheckbox']:checked + .check svg,
  #registered:checked + .check svg,
  #cbx2:checked + .check svg,
  #cbx3:checked + .check svg {
    stroke: #333;
  }
  #cbx4:checked + .check svg {
    stroke: #333;
  }
  #cbx5:checked + .check svg {
    stroke: #333;
  }
  #cbx:checked + .check svg path,
  [id^='formCheckbox']:checked + .check svg path,
  #registered:checked + .check svg path,
  #cbx2:checked + .check svg path,
  #cbx3:checked + .check svg path,
  #cbx4:checked + .check svg path,
  #cbx5:checked + .check svg path {
    stroke-dashoffset: 60;
    transition: all 0.3s linear;
  }
  #cbx:checked + .check svg polyline,
  [id^='formCheckbox']:checked + .check svg polyline,
  #registered:checked + .check svg polyline,
  #cbx2:checked + .check svg polyline,
  #cbx3:checked + .check svg polyline,
  #cbx4:checked + .check svg polyline,
  #cbx5:checked + .check svg polyline {
    stroke-dashoffset: 42;
    transition: all 0.2s linear;
    transition-delay: 0.15s;
  }

  .pipingColorGroup {
    .textCheckbox {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .colorsWrapper {
      /* width: 85%; */
      margin: auto;
      position: relative;
      &.disabled {
        opacity: 0.2;
        &:after {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          transition: all 0.1s ease-in-out;
        }
      }
    }
  }

  .hintWindow {
    position: absolute;
    width: 300px;
    top: 70px;
    left: calc(20% + 20px);
    z-index: 100;
    padding: 10px 20px;
    margin-left: 15px;
    font-size: 11px;
    text-align: left;
    opacity: 1;
    pointer-events: all;
    color: #fff;
    cursor: pointer;
    max-width: 90vw;

    &:before {
      content: '×';
      position: absolute;
      font-size: 34px;
      right: 6px;
      top: 4px;
      font-weight: 100;
      line-height: 18px;
    }
    &.closed {
      opacity: 0;
      pointer-events: none;
    }
    &.grey {
      background-color: rgba(130, 130, 130, 0.85);
    }
    &.blue {
      background-color: rgba(124, 144, 164, 0.85);
    }
  }
  .progressTabs,
  .buttonsBlock {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    .button {
      user-select: none;
      /* cursor: pointer; */
      /* background-color: #77674A; */
      /* color: #fff; */
      /* height: 35px; */
      /* width: 100px; */
      /* line-height: 35px; */
      /* font-size: 15px; */
      /* i {
          border-color: #fff;
        } */
      /* &:hover {
          opacity: 0.8;
        } */
    }
  }
  .progressTabs {
    top: 0;
    z-index: 100;
    height: 20px;
    margin: 20px 0;
    font-weight: 700;
    padding: 10px 20px;
    background-color: rgba(239, 239, 239, 0.6);
    border: 1px solid #d6d6d7;

    .tab {
      pointer-events: all;
      cursor: pointer;
      font-size: 12px;
      line-height: 20px;
      position: relative;
      &:after {
        content: '';
        width: 0%;
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        height: 3px;
        background-color: #b99777;
        z-index: 100;
        transition: width 0.3s ease-in-out;
      }
      &.active {
        &:after {
          width: 120%;
        }
      }
    }
    .split {
      margin: 0 10px;
      width: 2px;
      height: 100%;
      background-color: #000000;
    }
  }
  .centalPanel {
    width: 100%;
  }
  .panel {
    width: 35%;
    box-sizing: border-box;
    padding: 10px 20px;
    opacity: 1;
    transform: translateX(0);
    border-left: 1px solid #d6d6d7;
    border-right: 1px solid #d6d6d7;
    overflow: auto;
    background-color: rgba(239, 239, 239, 0.6);
    z-index: 1;
    transition: opacity 0.7s 0.4s, transform 0.5s 0.4s, visibility 0.1s 0.5s ease-in-out;
    visibility: visible;
    max-height: 90vh;
    max-height: calc(100vh - 54px);
    &.hidden {
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
      &.rightPanel {
        transform: translate(50px, 0);
      }
      &.leftPanel {
        transform: translate(-50px, 0);
      }
    }
    &.maxWidth {
      width: 100%;
      height: 100%;
    }
    .horizontalSplit {
      width: 100%;
      height: 1px;
      background-color: #cdccce;
      margin: 10px 0;
    }
    .group {
      position: relative;
      transition: height 0.2s 0.2s ease-in-out;
      &:after {
        content: '';
        pointer-events: none;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(239, 239, 239, 0);
        transition: all 0.1s ease-in-out;
      }
      &.disabled {
        height: 0;
        overflow: hidden;
        &:after {
          background-color: rgba(239, 239, 239, 0.8);
        }
      }
      .title {
        padding: 10px 0;
        display: block;
        font-weight: 700;
      }
      .scrollWrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .button {
          width: 29%;
          /* padding-bottom: 29%;
          height: 0; */
          height: 5vh;
          max-height: 80px;
          min-height: 20px;
          position: relative;
          margin-bottom: 18px;
          border: 2px solid #d6d6d7;
          cursor: pointer;
          border-radius: 2px;
          &.active {
            border-color: #a6906e;
          }
          .image {
            position: absolute;
            height: 94%;
            width: 94%;
            top: 3%;
            left: 3%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
          .name {
            position: absolute;
            &.backrestName,
            &.woodtrimName {
              width: max-content;
              bottom: -15px;
              left: 50%;
              transform: translateX(-50%);
            }
            &.armrestName {
              left: 3px;
              top: 3px;
            }
            &.layoutName {
              width: 100%;
              top: 105%;
              left: 50%;
              transform: translateX(-50%);
              /* background-color: rgba(239, 239, 239, 0.7); */
            }
          }
        }
      }
    }
    .woodtrimGroup {
      .scrollWrapper {
        &::after {
          content: '';
          width: 49%;
        }
        .button {
          width: 21%;
          /* padding-bottom: 21%; */
          .image {
            background-size: cover;
            height: 80%;
            width: 80%;
            top: 10%;
            left: 10%;
          }
        }
      }
    }
    .woodFinishGroup {
      .scrollWrapper {
        justify-content: space-around;
        .button {
          width: 19%;
          padding-bottom: 19%;
          height: 0;
          min-height: 0;
          background-color: #d9d9d9;
          border: none !important;
          position: relative;
          background-size: 131%;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 100%;
          opacity: 0.8;
        }
      }
    }
    .woodFinishGroup,
    .pinGroup {
      .scrollWrapper {
        .button {
          &.active {
            opacity: 1;
            .name {
              /* color: #fff; */
              font-weight: 900;
              &:after {
                content: '';
                width: 100%;
              }
            }
          }
          .name {
            /* line-height: 20px; */
            text-align: center;
            /* width: 100%; */
            &:after {
              content: '';
              position: absolute;
              height: 2px;
              transition: width 0.2s ease-in-out;
              background-color: #b99777;
              width: 0%;
              top: 12px;
              left: 50%;
              transform: translate(-50%);
              display: block;
            }
          }
        }
      }
    }
    .pinGroup {
      .scrollWrapper {
        &::after {
          content: '';
          width: 30%;
        }
        .button {
          opacity: 0.8;
          border: none !important;
        }
      }
    }
    .diamondGroup,
    .pipingGroup {
      .title {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 40px;
        text-align: left;
        label {
          padding-right: 5px;
        }
      }
      .buttonsWrapper {
        cursor: pointer;
        width: 100%;
        padding-bottom: 100%;
        /* height: 250px; */
        background-image: url(${require('../../img/diamondQuilting.png')});
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        border-radius: 5px;
      }
    }
    .leatherColorsGroup {
      position: relative;
      .leatherSampleImg {
        height: 30px;
        width: 30px;
        border-radius: 100%;
        position: absolute;
        left: 10px;
        top: 5px;
        background-size: 200%;
        background-repeat: no-repeat;
      }
      .title {
        text-align: left;
        padding-left: 50px;
        padding-top: 3px;
        padding-bottom: 12px;
      }
      .scrollWrapper {
        &::after {
          content: '';
          width: 49%;
        }
        .button {
          width: 21%;
          /* padding-bottom: 21%; */
          padding-bottom: 0;
          height: 5vh;
          max-height: 80px;
          min-height: 20px;
          .name {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            opacity: 1;
          }
          .image {
            background-size: cover;
            height: 84%;
            width: 84%;
            top: 8%;
            left: 8%;
          }
        }
      }
      .tooltipTrigger {
        display: inline-block;
        margin: 0 10px;
        color: #8a795c;
        font-size: 16px;
        cursor: pointer;
        position: absolute;
        &:hover {
          color: #333;
        }
      }
    }
    .stitchColorsGroup {
      .textCheckbox {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: -5px;
      }
      .disabled {
        opacity: 0.2;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          transition: all 0.1s ease-in-out;
        }
      }
      .textNote {
        margin-bottom: 10px;
      }
      .scrollWrapper {
        &::after {
          content: '';
          width: 32%;
        }
        .stitchButton {
          height: 25px;
          width: 27%;
          position: relative;
          border: none;
          padding: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          margin-left: 5px;
          margin-right: 5px;
          .stitchColor {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            font-weight: 700;
            &:after {
              content: '';
              position: absolute;
              height: 2px;
              transition: width 0.2s ease-in-out;
              background-color: #b99777;
              width: 0%;
              top: 12px;
              left: 50%;
              transform: translate(-50%);
              display: block;
            }
          }
          .stitchImage {
            height: 3px;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
          &.active {
            .stitchColor {
              font-weight: 900;
              &:after {
                content: '';
                width: 100%;
              }
            }
          }
        }
      }
    }
    .cupholdersGroup {
      .scrollWrapper {
        .cupholderButton {
          /* height: 85px;
          width: 49%; */
          width: 45%;
          padding-bottom: 45%;
          position: relative;
          border: none;
          /* padding: 0; */
          /* display: flex; */
          /* flex-direction: column; */
          /* justify-content: flex-end; */
          /* margin-bottom: 25px; */
          .cupholderFinish {
            position: relative;
            margin: 10px auto 5px auto;
            display: inline-block;
            font-weight: 700;
            &:after {
              content: '';
              position: absolute;
              height: 2px;
              transition: width 0.2s ease-in-out;
              background-color: #b99777;
              width: 0%;
              top: 12px;
              left: 50%;
              transform: translate(-50%);
              display: block;
            }
          }
          .cupholderImage {
            margin: 0 auto;
            width: 90%;
            padding-bottom: 90%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 5px;
          }
          &.active {
            .cupholderFinish {
              font-weight: 900;
              &:after {
                content: '';
                width: 100%;
              }
            }
          }
        }
      }
    }
    /* .stitchColorsGroup,
    .cupholdersGroup,
    .diamondGroup {
      margin-bottom: 10px;
      .title {
        padding-bottom: 20px;
      }
    } */
    .checkboxesGroup {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      & > div {
        width: 45%;
        /* padding-bottom: 45%; */
      }
    }
    .pipingGroup {
      .buttonsWrapper {
        /* height: 150px; */
        background-image: url(${require('../../img/piping.png')});
        border-radius: 5px;
      }
    }
    .layoutsGroup {
      .scrollWrapper .button {
        width: 44%;
        /* padding-bottom: 50px; */
        height: 5vh;
        max-height: 80px;
        min-height: 20px;
        margin-bottom: 40px;
        .name {
        }
      }

      .disabled {
        opacity: 0.2;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          transition: all 0.1s ease-in-out;
        }
      }
    }
  }
  .reportContainer {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: center;
    .report {
      .heading {
        text-align: right;
        font-size: 20px;
      }
      .prop {
        font-size: 18px;
        margin: 20px 0;
        width: 100%;
        text-align: right;
        .propName {
          display: inline-block;
          font-weight: 700;
          margin-right: 5px;
        }
        .propValue {
          display: inline-block;
        }
      }
    }
  }
  .submitPanel {
    /* width: 30%; */
    z-index: 101;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    & > *,
    input,
    textarea,
    .inputWrapper {
      -webkit-transform: translateZ(0px);
    }
  }
  .submitForm {
    pointer-events: all;
    .headerTitle {
      font-weight: 300;
      color: #7c90a4;
      font-size: 25px;
      letter-spacing: 0.5px;
      margin: 15px;
    }
    .formCheckbox {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      .label {
        text-align: left;
      }
    }
    .additional {
      .question {
        text-align: left;
      }
      .formCheckbox {
        margin: 15px 0;
      }
    }
  }

  .textNote {
    text-align: left;
    font-size: 12px;
    font-weight: 300;
  }

  .textCheckbox {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    line-height: 10px;
    cursor: pointer;
  }

  @keyframes logo-breath {
    0%,
    to {
      -webkit-transform: scale(0.86);
      transform: scale(0.86);
    }

    50% {
      -webkit-transform: scale(1.06);
      transform: scale(1.06);
    }
  }
  @media only screen and (min-width: 773px) {
    &.step1 {
      .back {
        visibility: hidden;
      }
    }
    &.step4 {
      .next {
        visibility: hidden;
      }
    }
  }

  @media only screen and (min-width: 1025px) {
    .panel {
      .title {
        font-size: 14px;
      }
      .woodtrimGroup {
        .scrollWrapper .button {
          margin-bottom: 28px;
        }
      }
      .buttonsWrapper {
        .button {
          .name {
            font-size: 10px;
            &.woodtrimName {
              font-size: 10px;
              font-size: 10px;
              height: 40px;
              margin-bottom: -28px;
              line-height: 10px;
            }
          }
        }
      }
      .leatherColorsGroup {
        .title {
          font-size: 16px;
        }

        .button {
          .name {
            font-size: 9px;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 773px) and (max-width: 1024px) {
    .progressTabs .tab {
      font-size: 10px;
      margin: auto;
    }
    .panel {
      .title {
        font-size: 10px;
      }
      .layoutsGroup {
        .scrollWrapper .button {
          margin-bottom: 30px;
        }
      }
      .buttonsWrapper {
        .button {
          .name {
            font-size: 7px;
          }
        }
      }
      .leatherColorsGroup {
        .title {
          font-size: 10px;
        }
        .leatherSampleImg {
          height: 25px;
          width: 25px;
        }
        .button {
          .name {
            font-size: 6px;
          }
        }
      }
      .cupholdersGroup {
        .buttonsWrapper {
          .scrollWrapper {
            .button {
              height: 0;
            }
          }
        }
      }
    }
  }

  /* @media only screen and (max-width: 772px) {
    flex-direction: column-reverse;
    align-items: flex-end;
    .panel {
      border: 1px solid #d6d6d7;
      .title {
        font-size: 10px;
      }
      .buttonsWrapper {
        .button {
          .name {
            font-size: 7px;
          }
        }
      }
      .leatherColorsGroup {
        .title {
          font-size: 10px;
        }
        .leatherSampleImg {
          height: 25px;
          width: 25px;
        }
        .button {
          .name {
            font-size: 6px;
          }
        }
      }
    }
  } */

  @media only screen and (max-width: 772px) {
    position: fixed;
    transform: translate3d(0, 0, 0);
    .hintWindow {
      top: 15px;
      left: 0;
      box-sizing: border-box;
    }
    .submitButton {
      bottom: 55px;
      width: 100%;
      position: fixed;
      transform: translate3d(0, 0, 0);
      z-index: 100;
      .button {
        box-sizing: border-box;
        width: 100%;
      }
    }
    .submitForm {
      form {
        padding-bottom: 50px;
      }
    }
    .buttonsBlock {
      bottom: 50%;
      width: 100%;
      position: fixed;
      left: 0;
      transform: translate3d(0, 0, 0);
      z-index: 100;
      .button {
        box-sizing: border-box;
        width: 100%;
        &.resize {
          font-size: 23px;
          width: 30%;
        }
      }
    }
    .contentWrapper {
      position: fixed;
      transform: translate3d(0, 0, 0);
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      flex-direction: column;
      height: calc(50% - 60px);
      margin-top: -60px;
      width: 100vw;
      /* position: relative; */
      display: block;
      align-self: flex-end;
      .panel {
        min-height: 51%;
        &:last-of-type {
          /* margin-bottom: 75px; */
        }
      }
      .progressTabs {
        display: none;
      }
      /* align-items: flex-start; */
      /* padding-bottom: 50px; */
      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        height: 1px;
        width: 100%;
        left: 0;
      }
      &.disabled {
        z-index: 100;
      }
      &:not(.disabled) {
        background-color: rgba(239, 239, 239, 1);
      }
      &:first-of-type.disabled {
        top: 0;
      }
      .panel {
        background-color: rgba(239, 239, 239, 1);
        &.hidden {
          transform: translate(0, 50px);
        }
        &.leftPanel .leatherPanel {
          flex-direction: row;
          display: flex;
        }
        border: none;
        /* height: 25%; */
        min-height: min-content;
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        .group {
          overflow: hidden;
        }
        .title {
          font-size: 14px;
        }
        .backrestGroup,
        .woodtrimGroup,
        .woodFinishGroup,
        .pinGroup {
          .scrollWrapper {
            min-height: 18vw;
          }
        }
        .backrestGroup,
        .armrestGroup,
        .stitchColorsGroup,
        .cupholdersGroup,
        .layoutsGroup,
        .pinGroup {
          .scrollWrapper .button {
            width: 12vw;
            height: 12vw;
            padding-bottom: 0;
            margin: 2px;
            .name {
              font-size: 10px;
            }
          }
        }
        .armrestGroup .scrollWrapper .button .name {
          font-size: 8px;
        }

        .cupholdersGroup {
          .buttonsWrapper {
            height: 30vw;
            .scrollWrapper {
              justify-content: space-around;
              .button {
                height: 25vw;
                min-width: 20%;
              }
            }
          }
        }
        .pinGroup {
          .scrollWrapper .button {
            width: 13vw;
          }
        }
        .stitchColorsGroup {
          .scrollWrapper .button {
            height: 8vw;
          }
        }
        .layoutsGroup {
          height: 20vh;
          min-height: 35vw;
          .buttonsWrapper {
            /* overflow: hidden; */
            height: 100%;
            /* width: 98vw; */
            .scrollWrapper {
              /* width: calc(16 * 20vw); */
              height: 100%;
              /* min-height: calc(100% + 1px); */
              .button {
                height: 10vw;
                width: 20vw;
                /* margin-bottom: 10vw; */
              }
            }
          }
        }
        .woodFinishGroup .scrollWrapper .button {
          width: 12vw;
          height: 12vw;
          padding-bottom: 0;
          margin-bottom: 20px;
        }
        .woodtrimGroup .scrollWrapper .button,
        .leatherColorsGroup .scrollWrapper .button,
        .woodFinishGroup .scrollWrapper .button {
          width: 12vw;
          height: 12vw;
          padding-bottom: 0;
          margin: 2px;
          max-height: unset;
          .name {
            font-size: 8px;
          }
        }
        .woodFinishGroup .scrollWrapper .button {
          width: 8vw;
          height: 8vw;
        }
        .checkboxesGroup {
          justify-content: space-around;
          .diamondGroup,
          .pipingGroup {
            padding-bottom: 0;
            max-width: 30vw;
          }
        }
        .buttonsWrapper {
          overflow: auto;
          -webkit-overflow-scrolling: touch;
          .scrollWrapper {
            flex-wrap: nowrap;
            min-width: max-content;
            -webkit-overflow-scrolling: auto;
            &::after {
              content: none;
            }
          }
        }
        .leatherColorsGroup {
          min-width: min-content;
          .title {
            font-size: 16px;
            padding-top: 3px;
            padding-bottom: 10px;
          }

          .button {
            .name {
              font-size: 9px;
            }
          }
        }
        .pipingColorGroup {
          .colorsWrapper {
            width: 100%;
            display: flex;
            overflow: auto;
          }
        }
      }
    }

    .mobileReport {
      z-index: 100;
      height: 45%;
      height: calc(50% - 40px);
      &,
      * {
        pointer-events: none;
      }
      .panel {
        width: 100vw;
        box-sizing: border-box;
        height: 100%;
        &:not(.disabled) {
          pointer-events: all;
        }
        .reportContainer {
          position: flex;
          top: 0;
          left: 0;
          .report {
            .heading {
              text-align: left;
              font-size: 16px;
            }
            .prop {
              font-size: 12px;
              margin: 5px 0;
              width: 100%;
              text-align: left;
              .propName {
                display: inline-block;
                font-weight: 700;
                margin-right: 5px;
              }
              .propValue {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .progressTabs {
      display: none;
    }

    &.step1 {
      .back {
        /* display: none; */
        pointer-events: none;
        a,
        i {
          color: transparent !important;
          border: transparent !important;
        }
      }
    }
    &.step4 {
      .next {
        /* display: none; */
        pointer-events: none;
        a,
        i {
          color: transparent !important;
          border: transparent !important;
        }
      }
      .contentWrapper {
        /* overflow: hidden; */
      }
      .submitPanel {
        height: 100%;
        /* max-height: 100%;
        overflow: scroll; */
      }
    }
    .button {
      margin: 0;
    }

    &.extendedRatio {
      .mainFrame iframe {
        height: calc(70% - 40px);
      }
      .buttonsBlock {
        bottom: 30%;
      }
      .contentWrapper {
        height: calc(30% - 60px);
        margin-top: -60px;
        .panel {
          min-height: 31%;
        }
      }
      .mobileReport {
        height: calc(70% - 40px);
      }
    }
  }
`

export const ExportTool = styled.div`
  overflow: auto;
  max-height: 70vh;
  margin-top: 20px;
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    & > * {
      margin: 0 10px;
      .button {
        margin: 10px 0 0 0;
      }
    }
  }
  .label {
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    margin: 5px 0;
  }
  .text {
    text-align: left;
    user-select: all;
    margin: 0 0 20px auto;
    width: 350px;
    max-width: 65vw;
    padding: 10px;
    border: 2px solid #b99777;
    .prop {
      font-size: 18px;
      /* width: 100%; */
      .propName {
        display: inline-block;
        font-weight: 700;
        margin-right: 5px;
      }
      .propValue {
        display: inline-block;
      }
    }
  }
  .screenshots {
    height: 320px;
    & > * {
      width: 300px;
    }
  }
`

export default ExportTool
