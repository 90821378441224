/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ReactTooltip from 'react-tooltip'
import ModelFrame from 'components/ModelFrame'
import LoadingScreen from 'components/LoadingScreen'
import Button from 'components/Button'
import Input from 'components/Input'
import Modal from 'components/Modal'
import ConfirmationPage from 'components/ConfirmationPage'
import ReactHintFactory from 'react-hint'
import 'react-hint/css/index.css'
import { sendEmail } from 'services/email-service'
import {
  armrest,
  backrest,
  woodtrim,
  woodFinish,
  leather,
  diamondQuilting,
  cupholder,
  stitch,
  piping,
  layout,
  pin as pinHelper,
} from 'helpers'
import { armrests, backrests, woodtrims, woodFinishes, leathers, cupholders, stitches, layouts, pins } from 'mappers'
import { ExportTool, Container } from './Configurator.style'
import logoImage from '../../img/logomark.png'
import { gaTrackNext, gaTrackSubmit, getStepFromQuery, updateStepQuery } from './utils'
const ReactHint = ReactHintFactory(React)

// window.toast = toast

function handler(e) {
  e.stopPropagation()
  e.preventDefault()
}

class Configurator extends Component {
  constructor(props) {
    super(props)
    const {
      arm,
      seat,
      wood,
      finish,
      pin,
      leather,
      cupholder,
      stitch,
      diamond,
      pipingMaterial,
      piping,
      layoutFront,
      layoutBack,
    } = props

    this.state = {
      apiLoaded: false,
      step: getStepFromQuery() || 1,
      loaderIsHidden: false,
      showHint: true,
      isModalOpen: false,
      isRatio50: false,
      showConfirmationPage: false,
      screenshots: {
        inProcess: false,
        width: 500,
        height: 500,
        images: [],
      },
      invalidEmail: false,
      parts: {
        arm: arm || armrests[4],
        seat: seat || backrests[0],
        wood: wood || woodtrims[4],
        finish: finish || woodFinishes[2],
        pin: pin || pins[0],
        leather: leather || leathers[0][0],
        cupholder: cupholder || cupholders[0],
        stitch: piping ? false : stitch || false,
        diamond: diamond || false,
        pipingMaterial: pipingMaterial || leathers[0][5],
        piping: piping || false,
        layoutFront: layoutFront || layouts[5],
        layoutBack: layoutBack || layouts[5],
      },
      form: {
        companyTypes: {},
        additionalRequirements: {},
      },
      goToStep1: false,
    }

    if (window.history.pushState) {
      var newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        this.generateQueryParams(this.state.parts)
      window.history.pushState({ path: newurl }, '', newurl)
    }
  }

  componentDidMount() {
    document.getElementById('configuratorWrapper').addEventListener('click', handler, true)

    setTimeout(() => this.setState({ goToStep1: true, step: 1 }), 3500)
  }

  componentDidUpdate(prevProps, prevState) {
    const { step, parts } = this.state
    if (JSON.stringify(parts) !== JSON.stringify(prevState.parts)) {
      if (window.history.pushState) {
        var newurl =
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname +
          this.generateQueryParams(parts)
        window.history.pushState({ path: newurl }, '', newurl)
      }
    }
    if (step !== prevState.step) {
      this.setState({
        showHint: true,
      })
      if (step === 3 || (step === 4 && window.api2)) {
        leather.changeColor(parts.leather.overwriteChannels, parts.leather.hex, window.api2, false, parts.diamond)
        cupholder.changeCupholder(parts.cupholder.overwriteChannels, parts.cupholder.hex, window.api2)
        window.api.gotoAnnotation(window.api._getAnnotationByName('step3').index)
      }
      if (step === 1 || step === 2) {
        window.api.gotoAnnotation(window.api._getAnnotationByName('default').index)
      }
    }
  }

  generateQueryParams = (parts, withExport = true) => {
    const {
      arm,
      seat,
      wood,
      finish,
      pin,
      leather,
      cupholder,
      stitch,
      diamond,
      pipingMaterial,
      piping,
      layoutFront,
      layoutBack,
    } = parts
    const params = {}
    const parameters = new URLSearchParams(window.location.search)

    if (parameters.has('export') && withExport) {
      params['export'] = true
    }

    params['home_theater_seat_armrest'] = arm.buttonName
    params['home_theater_chair_backrest'] = seat.buttonName
    params['wood_trim'] = wood.name
    params['wood_finish'] = finish.class
    params['metal_finish'] = pin.buttonName
    params['leather_color'] = leather.colorName
    params['leather_material'] = leather.leatherName
    params['cupholder'] = cupholder.name
    if (stitch) {
      params['stitch_color'] = stitch.name
    }
    if (diamond) {
      params['diamond'] = '1'
    }
    if (piping) {
      params['piping_color'] = pipingMaterial.colorName
      params['piping_material'] = pipingMaterial.leatherName
    }
    params['layout_front'] = layoutFront.buttonName
    params['layout_back'] = layoutBack && layoutBack.buttonName

    return `?${Object.keys(params)
      .map((param) => `${param}=${params[param]}`)
      .join('&')}`
  }

  onFrameLoaded = () => {
    const { parts } = this.state

    this.makeInitialConfiguration(parts)
    setTimeout(
      () =>
        this.setState({ apiLoaded: true }, () =>
          document.getElementById('configuratorWrapper').removeEventListener('click', handler, true)
        ),
      700
    )
  }

  on2ndFrameLoaded = (api2) => {
    const { parts } = this.state
    const { layoutFront, layoutBack } = parts

    this.setState({ apiLoaded: true }, () => {
      layout.showLayouts(layoutBack ? [layoutFront.node + 1, layoutBack.node + 2] : [layoutFront.node + 1])
      leather.changeColor(parts.leather.overwriteChannels, parts.leather.hex, api2, false, parts.diamond)
      cupholder.changeCupholder(parts.cupholder.overwriteChannels, parts.cupholder.hex, api2)
    })
  }

  makeInitialConfiguration = (parts) => {
    const {
      arm,
      seat,
      wood: woodTexture,
      finish,
      pin: chosenPin,
      leather: chosenLeather,
      pipingMaterial,
      piping: showPiping,
      diamond: showDiamond,
      stitch: chosenStitch,
    } = parts
    this.changeLayout()
    woodtrim.changeWoodtrimTexture(woodTexture.overwriteChannels, woodTexture.hex)
    armrest.showOneArm(arm.node)
    backrest.showOneSeat(seat.node)
    woodFinish.changeGlossiness(finish.overwriteChannels)
    pinHelper.changePinTexture(chosenPin.overwriteChannels, chosenPin.textureName)
    leather.changeColor(chosenLeather.overwriteChannels, chosenLeather.hex, window.api, true, parts.diamond)
    if (showPiping) {
      piping.show()
      this.changePiping(pipingMaterial)
    }
    if (showDiamond) {
      diamondQuilting.show()
    }
    if (chosenStitch) {
      stitch.changeStitchesColor(chosenStitch.overwriteChannels, chosenStitch.hex)
    } else {
      stitch.changeStitchesColor(stitches[0].overwriteChannels, chosenLeather.hex)
    }
  }

  changeLayout = (newLayout, row) => {
    let { parts } = this.state

    const layoutFront = row === 1 ? newLayout : parts.layoutFront
    const layoutBack = row === 2 ? newLayout : parts.layoutBack

    layout.showLayouts(!!layoutBack ? [layoutFront.node + 1, layoutBack.node + 2] : [layoutFront.node + 1]).then(() => {
      parts = { ...parts, layoutFront, layoutBack }

      this.setState({ parts })
    })
  }

  changeBackrest = (seat) => {
    const { parts: prevParts } = this.state
    const parts = { ...prevParts, seat }

    this.setState({ parts }, () => {
      // diamondQuilting.hide()
      backrest.showOneSeat(seat.node)
    })
  }

  changeArmrest = (arm) => {
    const { parts: prevParts } = this.state
    const parts = { ...prevParts, arm }

    this.setState({ parts }, () => {
      armrest.showOneArm(arm.node)
    })
  }

  changeWoodtrim = (wood) => {
    const { parts: prevParts } = this.state
    const parts = { ...prevParts, wood }

    this.setState({ parts }, () => {
      woodtrim.changeWoodtrimTexture(wood.overwriteChannels, wood.hex)
    })
  }

  changeWoodFinish = (finish = this.state.parts.finish) => {
    const { parts: prevParts } = this.state
    const parts = { ...prevParts, finish }

    this.setState({ parts }, () => {
      woodFinish.changeGlossiness(finish.overwriteChannels)
    })
  }

  changeLeather = (chosenLeather) => {
    const { parts: prevParts } = this.state
    const parts = { ...prevParts, leather: chosenLeather }
    const defaultStitch = !parts.stitch && { ...stitches[0], hex: chosenLeather.hex }

    this.setState({ parts }, () => {
      leather.changeColor(chosenLeather.overwriteChannels, chosenLeather.hex, window.api, true, parts.diamond)
      if (defaultStitch) {
        stitch.changeStitchesColor(defaultStitch.overwriteChannels, defaultStitch.hex)
      }
    })
  }

  changeCupholder = (chosenCupholder) => {
    const { parts: prevParts } = this.state
    const parts = { ...prevParts, cupholder: chosenCupholder }

    this.setState({ parts }, () => {
      cupholder.changeCupholder(chosenCupholder.overwriteChannels, chosenCupholder.hex)
      window.api.gotoAnnotation(window.api._getAnnotationByName('cupholders').index)
    })
  }

  toggleDiamond = () => {
    const { parts } = this.state
    const newState = !parts.diamond
    this.setState(
      {
        parts: {
          ...parts,
          diamond: !!newState,
        },
      },
      () => {
        if (newState) {
          diamondQuilting.show()
        } else {
          diamondQuilting.hide()
        }
      }
    )
  }

  togglePiping = () => {
    const { parts } = this.state
    const newState = !parts.piping
    this.setState(
      {
        parts: {
          ...parts,
          piping: !!newState,
        },
      },
      () => {
        if (newState) {
          piping.show()
          this.changePiping(parts.pipingMaterial)
          // hide stitch
          if (!!parts.stitch) this.toggleStitch(true)
        } else {
          piping.hide()
        }
      }
    )
  }

  toggleStitch = () => {
    const { parts } = this.state
    const newState = !parts.stitch
    if (newState) {
      this.changeStitch(stitches[0])
    } else {
      this.setState({
        parts: {
          ...parts,
          stitch: null,
        },
      })
      stitch.changeStitchesColor(stitches[0].overwriteChannels, parts.leather.hex)
    }
  }

  changeStitch = (chosenStitch) => {
    const { parts: prevParts } = this.state
    const parts = { ...prevParts, stitch: chosenStitch }

    this.setState({ parts }, () => {
      stitch.changeStitchesColor(chosenStitch.overwriteChannels, chosenStitch.hex)
      window.api.gotoAnnotation(window.api._getAnnotationByName('threads').index)
      // hide piping
      if (!!parts.piping) this.togglePiping()
    })
  }

  toggleBackRow = () => {
    const { parts } = this.state
    const newState = !parts.layoutBack
    this.setState(
      {
        parts: {
          ...parts,
          layoutBack: !!newState ? layouts[4] : false,
        },
      },
      () => {
        if (newState) {
          layout.showBackRow(layouts[4].node)
        } else {
          layout.hideBackRow()
        }
      }
    )
  }

  changePiping = (chosenPiping) => {
    const { parts: prevParts } = this.state
    const parts = { ...prevParts, pipingMaterial: chosenPiping }

    this.setState({ parts }, () => {
      piping.changeColor(chosenPiping.overwriteChannels, chosenPiping.hex)
    })
  }

  changePin = (chosenPin) => {
    const { parts: prevParts } = this.state
    const parts = { ...prevParts, pin: chosenPin }

    this.setState({ parts }, () => {
      pinHelper.changePinTexture(chosenPin.overwriteChannels, chosenPin.textureName)
    })
  }

  getProperties = () => {
    const { parts } = this.state
    const {
      arm,
      seat,
      wood,
      finish,
      leather,
      cupholder,
      stitch,
      diamond,
      pipingMaterial,
      piping,
      layoutFront,
      layoutBack,
      pin,
    } = parts
    return {
      model: arm.buttonName + seat.buttonName,
      material: leather.leatherName,
      color: leather.colorName,
      stitching: stitch ? stitch.name : null,
      piping: piping ? `${pipingMaterial.colorName} (${pipingMaterial.leatherName})` : null,
      woodtrim: arm.containsWood ? wood.name : null,
      wood_finish: arm.containsWood ? finish.name : null,
      metal_finish: arm && arm.buttonName === 'L' ? pin && pin.buttonName : null,
      diamond_quilting: !['seat_2', 'seat_5', 'seat_6'].includes(seat.node) ? (!!diamond ? 'Yes' : 'No') : null,
      cupholder: cupholder.name,
      foam_firmness: null,
      front_row_layout: layoutFront.buttonName,
      back_row_layout: layoutBack && layoutBack.buttonName,
    }
  }

  getPanels = (stepN, side) => {
    const { parts, apiLoaded, step, emailIsSending, goToStep1 } = this.state
    const panels = {
      1: {
        left: (
          <div className={'leftPanel panel' + ((apiLoaded || goToStep1) && step === 1 ? ' ' : ' hidden')} id="leathers">
            <div className="leftPanelContainer panelContainer leatherPanel">
              {leathers.map((leather, i) => (
                <div className="group leatherColorsGroup">
                  <div className="title">
                    <div
                      className="leatherSampleImg"
                      style={{
                        backgroundImage: `url(${leather[0].sampleImageUrl})`,
                      }}
                    />
                    {leather[0].leatherName}
                    <a className="tooltipTrigger" data-rh={leather[0].tooltip} data-rh-at="bottom">
                      ⓘ
                    </a>
                    <ReactHint autoPosition={false} events={{ click: true }} />
                    <div className="textNote">Custom colors available upon request</div>
                  </div>
                  <div className="buttonsWrapper">
                    <div className="scrollWrapper">
                      {leather.map((color) => (
                        <a
                          data-tip={color.colorName}
                          className={`button ${
                            color.colorName === parts.leather.colorName &&
                            color.leatherName === parts.leather.leatherName
                              ? 'active'
                              : ''
                          }`}
                          onClick={() => this.changeLeather(color)}
                        >
                          <span
                            className="image"
                            style={{
                              backgroundColor: color.hex,
                            }}
                          />
                          {/* for mobile leave this option */}
                          <div className="leatherColorName name onlyMobile">{color.colorName}</div>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ),
        right: (
          <div className={'rightPanel panel' + ((apiLoaded || goToStep1) && step === 1 ? ' ' : ' hidden')}>
            <div className="rightPanelContainer panelContainer">
              <div className="group backrestGroup">
                <div className="title">
                  Choose Your Backrest
                  <div className="textNote" style={{ textAlign: 'center' }}>
                    All designs Patent Protected by Elite HTS
                  </div>
                </div>
                <div className="buttonsWrapper">
                  <div className="scrollWrapper">
                    {backrests.map((seat) => (
                      <div
                        className={`button ${seat.node === parts.seat.node ? 'active' : ''}`}
                        onClick={() => this.changeBackrest(seat)}
                      >
                        <div
                          className="image"
                          style={{
                            backgroundImage: `url(${seat.buttonImage})`,
                          }}
                        />
                        <div className="backrestName name">{seat.buttonName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="horizontalSplit" />
              <div className="group armrestGroup">
                <div className="title">
                  Choose Your Arm
                  <div className="textNote" style={{ textAlign: 'center' }}>
                    All designs Patent Protected by Elite HTS
                  </div>
                </div>
                <div className="buttonsWrapper">
                  <div className="scrollWrapper">
                    {armrests.map((arm) => (
                      <div
                        className={`button ${arm.node === parts.arm.node ? 'active' : ''}`}
                        onClick={() => this.changeArmrest(arm)}
                      >
                        <div className="image" style={{ backgroundImage: `url(${arm.buttonImage})` }} />
                        <div className="armrestName name">{arm.buttonName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={'group woodFinishGroup' + (parts.arm.containsWood ? '' : ' disabled')}>
                <div className="title">Choose Your Wood Finish</div>
                <div className="buttonsWrapper">
                  <div className="scrollWrapper">
                    {woodFinishes.map((finish) => (
                      <div
                        className={`button ${finish.name === parts.finish.name ? 'active' : ''}`}
                        style={{
                          backgroundImage: `url(${finish.buttonImage}), url(${finish.woodImage})`,
                        }}
                        onClick={() => this.changeWoodFinish(finish)}
                      >
                        <div className={`woodtrimName name`}>{finish.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={'group woodtrimGroup' + (parts.arm.containsWood ? '' : ' disabled')}>
                <div className="title">Choose Your Woodtrim</div>
                <div className="buttonsWrapper">
                  <div className="scrollWrapper">
                    {woodtrims.map((wood) => (
                      <div
                        className={`button ${wood.name === parts.wood.name ? 'active' : ''}`}
                        onClick={() => this.changeWoodtrim(wood)}
                      >
                        <div
                          className="image"
                          style={{
                            backgroundColor: wood.hex,
                            backgroundImage: `url(${wood.url})`,
                          }}
                        />
                        <div className="woodtrimName name">{wood.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={'group pinGroup' + (parts.arm.buttonName === 'L' ? '' : ' disabled')}>
                <div className="title">Choose Your Metal Finish</div>
                <div className="buttonsWrapper">
                  <div className="scrollWrapper">
                    {pins.map((pin) => (
                      <div
                        className={`button ${parts.pin && pin.buttonName === parts.pin.buttonName ? 'active' : ''}`}
                        onClick={() => this.changePin(pin)}
                      >
                        <div
                          className="image"
                          style={{
                            backgroundImage: `url(${pin.buttonImage})`,
                          }}
                        />
                        <div className="woodtrimName name">{pin.buttonName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      2: {
        left: (
          <div className={'leftPanel panel' + ((apiLoaded || goToStep1) && step === 2 ? ' ' : ' hidden')}>
            <div className="leftPanelContainer panelContainer">
              <div className="group stitchColorsGroup">
                <label className="textCheckbox">
                  <input
                    type="checkbox"
                    id="cbx5"
                    style={{ display: 'none' }}
                    checked={!!parts.stitch && !!parts.stitch.name}
                    onChange={this.toggleStitch}
                  />
                  <label for="cbx5" class="check">
                    <svg width="18px" height="18px" viewBox="0 0 18 18">
                      <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
                      <polyline points="1 9 7 14 15 4" />
                    </svg>
                  </label>
                  <div className="title">Contrast Stitching</div>
                </label>

                <div className="textNote" style={{ textAlign: 'center' }}>
                  Custom colors available upon request
                </div>
                <div className={'buttonsWrapper' + (!!parts.stitch ? '' : ' disabled')}>
                  <div className="scrollWrapper">
                    {stitches.map((stitch) => (
                      <div
                        className={`stitchButton button ${
                          parts.stitch && stitch.name === parts.stitch.name ? 'active' : ''
                        }`}
                        onClick={() => this.changeStitch(stitch)}
                      >
                        <div className="stitchColor name">{stitch.name}</div>
                        <div
                          className="stitchImage"
                          style={{
                            color: stitch.hex,
                            backgroundColor: stitch.hex,
                            backgroundImage: `url(${stitch.buttonImg})`,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="group cupholdersGroup">
              <div className="title">Choose Your Cupholder Finish</div>
              <div className="buttonsWrapper">
                <div className="scrollWrapper">
                  {cupholders.map((finish) => (
                    <div
                      className={`cupholderButton button ${finish.name === parts.cupholder.name ? 'active' : ''}`}
                      onClick={() => this.changeCupholder(finish)}
                    >
                      <div className="cupholderFinish name">{finish.name}</div>
                      <div
                        className="cupholderImage"
                        style={{
                          backgroundImage: `url(${finish.buttonImg})`,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="group checkboxesGroup">
              {!['seat_2', 'seat_5', 'seat_6'].includes(parts.seat.node) && (
                <div className="diamondGroup">
                  <label className="title">
                    <input
                      type="checkbox"
                      id="cbx"
                      style={{ display: 'none' }}
                      checked={parts.diamond}
                      onChange={this.toggleDiamond}
                    />
                    <label for="cbx" class="check">
                      <svg width="18px" height="18px" viewBox="0 0 18 18">
                        <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
                        <polyline points="1 9 7 14 15 4" />
                      </svg>
                    </label>
                    Diamond Quilting
                  </label>
                  <div className="buttonsWrapper" onClick={this.toggleDiamond} />
                </div>
              )}
            </div>
          </div>
        ),
        right: (
          <div className={'rightPanel panel' + ((apiLoaded || goToStep1) && step === 2 ? ' ' : ' hidden')}>
            <div className="rightPanelContainer panelContainer">
              <div className="group pipingColorGroup">
                <label className="textCheckbox">
                  <input
                    type="checkbox"
                    id="cbx4"
                    style={{ display: 'none' }}
                    checked={parts.piping}
                    onChange={this.togglePiping}
                  />
                  <label for="cbx4" class="check">
                    <svg width="18px" height="18px" viewBox="0 0 18 18">
                      <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
                      <polyline points="1 9 7 14 15 4" />
                    </svg>
                  </label>
                  <div className="title">Piping Trim</div>
                </label>

                <div className={'colorsWrapper' + (!!parts.piping ? '' : ' disabled')}>
                  {leathers.map((leather) => (
                    <div className="group leatherColorsGroup">
                      <div className="title">
                        <div
                          className="leatherSampleImg"
                          style={{
                            backgroundImage: `url(${leather[0].sampleImageUrl})`,
                          }}
                        />
                        {leather[0].leatherName}
                        <a
                          className="tooltipTrigger"
                          // data-custom
                          data-custom={leather[0].tooltip}
                          data-custom-at="bottom"
                        >
                          ⓘ
                        </a>
                        <ReactHint attribute="data-custom" events={{ click: true }} autoPosition={false} />
                        <div className="textNote">Custom colors available upon request</div>
                      </div>
                      <div className="buttonsWrapper">
                        <div className="scrollWrapper">
                          {leather.map((color) => (
                            <a
                              data-tip={color.colorName}
                              className={`button ${
                                color.colorName === parts.pipingMaterial.colorName &&
                                color.leatherName === parts.pipingMaterial.leatherName
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={() => this.changePiping(color)}
                            >
                              <span
                                className="image"
                                style={{
                                  backgroundColor: color.hex,
                                }}
                              />
                              {/* for mobile leave this option */}
                              <div className="leatherColorName name onlyMobile">{color.colorName}</div>
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ),
      },
      3: {
        left: (
          <div className={'leftPanel panel' + ((apiLoaded || goToStep1) && step === 3 ? ' ' : ' hidden')}>
            <div className="group layoutsGroup">
              <div className="title">Choose Back Row Layout</div>
              <label className="textNote textCheckbox">
                <input
                  type="checkbox"
                  id="cbx3"
                  style={{ display: 'none' }}
                  checked={!parts.layoutBack}
                  onChange={this.toggleBackRow}
                />
                <label for="cbx3" class="check">
                  <svg width="18px" height="18px" viewBox="0 0 18 18">
                    <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
                    <polyline points="1 9 7 14 15 4" />
                  </svg>
                </label>
                select this box if you require only one row of seats
              </label>
              <div className={'buttonsWrapper' + (!parts.layoutBack ? ' disabled' : '')}>
                <div className="scrollWrapper">
                  {layouts.map((layout) => (
                    <div
                      className={`button ${parts.layoutBack && layout.node === parts.layoutBack.node ? 'active' : ''}`}
                      onClick={() => this.changeLayout(layout, 2)}
                    >
                      <div
                        className="image"
                        style={{
                          backgroundImage: `url(${layout.buttonImage})`,
                        }}
                      />
                      <div className="layoutName name">
                        <b>{layout.buttonName}</b> <br />
                        <span style={{ fontStyle: 'italic' }}>{layout.width}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ),
        right: (
          <div className={'rightPanel panel' + ((apiLoaded || goToStep1) && step === 3 ? ' ' : ' hidden')}>
            <div className="group layoutsGroup">
              <div className="title">Choose Front Row Layout</div>
              <div className="buttonsWrapper">
                <div className="inner" />

                <div className="scrollWrapper">
                  {layouts.map((layout) => (
                    <div
                      className={`button ${layout.node === parts.layoutFront.node ? 'active' : ''}`}
                      onClick={() => this.changeLayout(layout, 1)}
                    >
                      <div
                        className="image"
                        style={{
                          backgroundImage: `url(${layout.buttonImage})`,
                        }}
                      />
                      <div className="layoutName name">
                        <b>{layout.buttonName}</b> <br />
                        <span style={{ fontStyle: 'italic' }}>{layout.width}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ),
      },
      4: {
        left: (
          <div className={'leftPanel panel maxWidth' + ((apiLoaded || goToStep1) && step === 4 ? ' ' : ' hidden')}>
            <div className="leftPanelContainer panelContainer reportContainer">
              <div className="report">
                <div className="heading">YOU HAVE CHOSEN</div>
                {Object.keys(this.getProperties()).map(
                  (name) =>
                    this.getProperties()[name] && (
                      <div className="prop">
                        <div className="propName">{name.split('_').join(' ').toUpperCase()}:</div>
                        <div className="propValue">{this.getProperties()[name]}</div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        ),
        right: (
          <div
            className={'rightPanel panel submitPanel' + ((apiLoaded || goToStep1) && step === 4 ? ' ' : ' hidden')}
            style={{ backgroundColor: 'rgba(239,239,239,1)' }}
          >
            <div className="rightPanelContainer panelContainer">
              <label className="submitForm">
                <div className="headerTitle">Submit for Quote</div>
                <div className="question">
                  You have selected a custom configuration which is hand made in Canada and comes with an industry
                  leading 20 Year Warranty. <br />
                  <br /> Please allow 1 business day for price quotation. For immediate pricing requests, please call
                  our concierge/sales team at 604-575-8310 (8am to 4pm PST). International residents may also call 0141
                  445 4195 (8:30am to 4:30pm GMT/UTC) <br />
                  <br />
                  Please note retail pricing starts at $2250 MSRP per seating position. Wholesale pricing available to
                  registered home professionals including Theater Installers, Interior Designers, Home Builders and
                  Design Firms.
                </div>
                <form onSubmit={this.onSubmit}>
                  <input type="text" autofocus="autofocus" style={{ display: 'none' }} />
                  <Input
                    label="Your Name"
                    contextThis={this}
                    name="name"
                    placeholder="Please fill out this field"
                    required
                  />
                  <Input
                    label="Your Email"
                    contextThis={this}
                    name="email"
                    type="email"
                    isInvalid={this.state.invalidEmail}
                    placeholder="Please fill out this field"
                    required
                  />
                  <Input
                    label="Your Phone (optional)"
                    contextThis={this}
                    name="phone"
                    type="phone"
                    placeholder="Please fill out this field"
                  />
                  <Input
                    label="Your City & Country"
                    contextThis={this}
                    name="location"
                    placeholder="Please fill out this field"
                    required
                  />
                  <Input
                    label="Additional Notes"
                    textarea
                    contextThis={this}
                    name="notes"
                    placeholder="Please fill out this field"
                  />
                  <label className="formCheckbox">
                    <input
                      type="checkbox"
                      id="registered"
                      style={{ display: 'none' }}
                      checked={this.state.isRegistered}
                      onChange={() =>
                        this.setState({
                          isRegistered: !this.state.isRegistered,
                        })
                      }
                    />
                    <label for="registered" class="check">
                      <svg width="18px" height="18px" viewBox="0 0 18 18">
                        <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
                        <polyline points="1 9 7 14 15 4" />
                      </svg>
                    </label>
                    <div className="label">
                      I am a registered home professional.
                      <br />
                      <span style={{ fontSize: '10px' }}>
                        (ie. Interior Designer, Home Theatre Installer, Home Builder, Design Firm)
                      </span>
                    </div>
                  </label>
                  <input
                    type="submit"
                    hidden
                    ref={(ref) => {
                      this.submit = ref
                    }}
                  />

                  {this.state.isRegistered && (
                    <div className={`additional`}>
                      <div className="horizontalSplit" />
                      <Input
                        label="Company Name"
                        contextThis={this}
                        placeholder="Please fill out this field"
                        required={this.state.isRegistered}
                        name="companyName"
                      />
                      <Input
                        label="Company Email"
                        contextThis={this}
                        placeholder="Please fill out this field"
                        required={this.state.isRegistered}
                        name="companyEmail"
                      />
                      <Input
                        label="Company Website"
                        contextThis={this}
                        placeholder="Please fill out this field"
                        required={this.state.isRegistered}
                        name="companyWebsite"
                      />
                      <Input
                        label="Company Phone"
                        contextThis={this}
                        placeholder="Please fill out this field"
                        required={this.state.isRegistered}
                        name="companyPhone"
                      />
                      <div className="question">Which of the following best describes your company?</div>
                      {[
                        'AV Dealer/Installer',
                        'Design Firm',
                        'Home Builder',
                        'Interior Design',
                        'Architect',
                        'Other',
                      ].map((p, i) => (
                        <label className="formCheckbox">
                          <input
                            type="checkbox"
                            id={'formCheckbox' + i}
                            style={{ display: 'none' }}
                            checked={this.state.form.companyTypes[p]}
                            onChange={() => {
                              const form = this.state.form
                              form.companyTypes = {
                                ...form.companyTypes,
                                [p]: !this.state.form.companyTypes[p],
                              }
                              this.setState({
                                form,
                              })
                            }}
                          />
                          <label for={'formCheckbox' + i} class="check">
                            <svg width="18px" height="18px" viewBox="0 0 18 18">
                              <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
                              <polyline points="1 9 7 14 15 4" />
                            </svg>
                          </label>
                          <div className="label">{p}</div>
                        </label>
                      ))}

                      <div className="question">In addition to a price quote, do you require any of the following?</div>
                      {['Material Samples', '3D Layout Drawings'].map((p, i) => (
                        <label className="formCheckbox">
                          <input
                            type="checkbox"
                            id={'formCheckbox0' + i}
                            style={{ display: 'none' }}
                            checked={this.state.form.additionalRequirements[p]}
                            onChange={() => {
                              const form = this.state.form
                              form.additionalRequirements = {
                                ...form.additionalRequirements,
                                [p]: !this.state.form.additionalRequirements[p],
                              }
                              this.setState({
                                form,
                              })
                            }}
                          />
                          <label for={'formCheckbox0' + i} class="check">
                            <svg width="18px" height="18px" viewBox="0 0 18 18">
                              <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
                              <polyline points="1 9 7 14 15 4" />
                            </svg>
                          </label>
                          <div className="label">{p}</div>
                        </label>
                      ))}
                    </div>
                  )}
                  <Button
                    className="button submitButton onlyDesktop"
                    wide
                    isLoading={emailIsSending}
                    onClick={(e) => {
                      e.preventDefault()

                      if (this.submit) {
                        this.submit.click()
                        gaTrackSubmit()
                      }
                    }}
                  >
                    <a>Submit for Price Quote</a>
                  </Button>
                </form>
              </label>
            </div>
          </div>
        ),
      },
    }
    return panels[stepN][side]
  }

  getStepHint = (step) => {
    const hints = {
      1: [
        'Welcome to the Elite HTS Chair Customizer',
        'As the manufacturer, we can customize your theater chair anyway you like.',
        'After you have made your selections, please click the NEXT STEP button to view additional options.',
      ],
      3: [
        'Please select your configuration and quantity for each row.',
        '(For larger VIP Cinemas, or if you require more than 2 rows, please contact customer service)',
        'Custom configurations and arm widths available upon request.',
      ],
    }

    return hints[step]
  }

  goToConfirmation() {
    // const { parts } = this.state;
    // if (window.history.pushState) {
    //   var newurl =
    //     window.location.protocol +
    //     "//" +
    //     window.location.host +
    //     window.location.pathname +
    //     this.generateQueryParams(parts) +
    //     "&step=confirmation";
    //   window.history.pushState({ path: newurl }, "", newurl);
    // }
    if (window.location.replace) {
      window.location.replace('https://elitehts.com/you-are-awesome-at-this/')
    }
    // this.setState({
    //   showConfirmationPage: true
    // });
  }

  onSubmit = (e) => {
    const { form } = this.state
    e.preventDefault()
    // regex for email
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (re.test(String(form.email).toLowerCase())) {
      console.log('fine, sending data')
      this.setState({ invalidEmail:  false})
      const props = this.getProperties()
      const chairProps = Object.keys(props)
          .filter((k) => !!props[k])
          .map((p) => ({
            name: p
                .split('_')
                .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
                .join(' '),
            value: props[p],
          }))

      this.setState({
        emailIsSending: true,
      })
      sendEmail(
          {
            ...form,
            companyTypes: Object.keys(form.companyTypes).filter((k) => !!form.companyTypes[k]),
            additionalRequirements: Object.keys(form.additionalRequirements).filter(
                (k) => !!form.additionalRequirements[k]
            ),
            chairProps,
            // url: window.location ? window.location.href : null,
            url: 'https://elitehts.com/build-your-theater-chair/' + this.generateQueryParams(this.state.parts),
          },
          (msg, status) => {
            // console.log(msg)
            this.setState({
              emailIsSending: false,
              status,
              message:
                  status === 'success'
                      ? 'Thank You! Your quotation request has been successfully submitted. Our current response time is less than 1 business day. For express service, please speak to our project managers at 1-604-575-8310 (8am to 4pm PST)'
                      : 'Something went wrong.',
            })
            if (status === 'success') {
              // toast.success(
              //   'Thank You! Your quotation request has been successfully submitted. Our current response time is less than 1 business day. For express service, please speak to our project managers at 1-604-575-8310 (8am to 4pm PST)',
              //   {
              //     position: 'top-center',
              //     autoClose: 7000,
              //     hideProgressBar: true,
              //     closeOnClick: false,
              //     pauseOnHover: true,
              //     draggable: false,
              //   }
              // )
              this.goToConfirmation()
            } else {
              toast.error('Something went wrong.', {
                position: 'top-center',
                autoClose: 7000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
              })
            }
          }
      )

    } else {
      console.log('Email check failed')
      this.setState({ invalidEmail:  true})
    }

  }

  toggleModal = (show = !this.state.isModalOpen) => {
    if (show) {
      this.makeScreenshots()
    }
    this.setState({ isModalOpen: show })
  }

  makeScreenshots = () => {
    const { screenshots } = this.state
    const { width, height } = screenshots

    screenshots.inProcess = true
    screenshots.images = []

    this.setState({ screenshots }, () => {
      window.api.gotoAnnotation(window.api._getAnnotationByName('screenshot1').index)
      setTimeout(
        () =>
          window.api.getScreenShot(width, height, 'image/png', (err, res1) => {
            window.api.gotoAnnotation(window.api._getAnnotationByName('screenshot2').index)
            setTimeout(() => window.api.gotoAnnotation(window.api._getAnnotationByName('screenshot2').index), 1500)
            setTimeout(() => window.api.gotoAnnotation(window.api._getAnnotationByName('screenshot2').index), 2500)

            screenshots.images.push(res1)
            setTimeout(
              () =>
                window.api.getScreenShot(width, height, 'image/png', (err, res2) => {
                  screenshots.images.push(res2)
                  screenshots.inProcess = false
                  this.setState({ screenshots })
                }),
              4000
            )
          }),
        2000
      )

      window.api.getScreenShot(width, height, 'image/png')
    })
  }

  toggleRatios = () => {
    this.setState({
      isRatio50: !this.state.isRatio50,
    })
  }

  render() {
    const {
      emailIsSending,
      apiLoaded,
      step,
      prevStep,
      loaderIsHidden,
      showHint,
      isModalOpen,
      screenshots,
      showConfirmationPage,
      isRatio50,
      goToStep1,
    } = this.state
    const { forExport } = this.props
    return (
      <React.Fragment>
        <Container
          className={
            (apiLoaded || goToStep1 ? 'isLoaded' : 'isLoading') + ` step${step} ${isRatio50 ? '' : ' extendedRatio'}`
          }
        >
          {apiLoaded && (
            <ModelFrame
              on2ndFrameLoaded={this.on2ndFrameLoaded}
              uid="56c6362f468f465f93e51341b0a1739f"
              hidden={step !== 3 && step !== 4}
              layoutFrame
              minimized={step === 3 || step === 4}
            />
          )}
          <ModelFrame
            onFrameLoaded={this.onFrameLoaded}
            // uid="70955d7ee2ce4d82835265050133918c"
            uid="0086b4f9981f44419f0cca1d86ebaea0"
            isTransparent={!!forExport}
            styles={{ opacity: apiLoaded ? 1 : 0 }}
          />
          {!apiLoaded && (
            <div className="loadingOveflow">
              <svg className="spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
              </svg>
              <div className="loadingMessage">
                Loading Custom Options
                {/* <span>.</span>
                <span>.</span>
                <span>.</span> */}
              </div>
            </div>
          )}
          {!loaderIsHidden && (
            <LoadingScreen
              // isLoaded={false}
              isLoaded={apiLoaded || goToStep1}
              hideLoader={() => this.setState({ loaderIsHidden: true })}
            />
          )}

          <div
            className={
              'hintWindow' + (showHint && !!this.getStepHint(step) ? '' : ' closed') + (step === 3 ? ' blue' : ' grey')
            }
            onClick={() =>
              this.setState({
                showHint: false,
              })
            }
          >
            {/* <div className="close">x</div> */}
            {(this.getStepHint(step) || (prevStep && this.getStepHint(prevStep)) || []).map((text) => (
              <span>
                <br />
                {text}
                <br />
              </span>
            ))}
          </div>
          <div className={'contentWrapper' + (step !== 1 ? ' disabled' : '')}>
            {this.getPanels(1, 'left')}
            <div className="centalPanel" />
            {this.getPanels(1, 'right')}
          </div>
          <div className={'contentWrapper' + (step !== 2 ? ' disabled' : '')}>
            {this.getPanels(2, 'left')}
            <div className="centalPanel" />
            {this.getPanels(2, 'right')}
          </div>
          <div className={'contentWrapper block' + (step !== 3 ? ' disabled' : '')}>
            {this.getPanels(3, 'left')}
            <div className="centalPanel" />
            {this.getPanels(3, 'right')}
          </div>
          <div className={'contentWrapper' + (step !== 4 ? ' disabled' : '')}>
            <div className="onlyDesktop">{this.getPanels(4, 'left')}</div>
            <div className="centalPanel" />
            {this.getPanels(4, 'right')}
          </div>
          <div className="onlyMobile mobileReport">{this.getPanels(4, 'left')}</div>
          <div className="progressTabs">
            <div
              className={'tab' + (step === 1 ? ' active' : '')}
              onClick={() => this.setState({ prevStep: step, step: 1 })}
            >
              <span className="notTablet">STEP 1.</span> Build Your Chair
            </div>
            <div className="split" />
            <div
              className={'tab' + (step === 2 ? ' active' : '')}
              onClick={() => this.setState({ prevStep: step, step: 2 })}
            >
              <span className="notTablet">STEP 2.</span> Options
            </div>
            <div className="split" />
            <div
              className={'tab' + (step === 3 ? ' active' : '')}
              onClick={() => this.setState({ prevStep: step, step: 3 })}
            >
              <span className="notTablet">STEP 3.</span> Layouts
            </div>
            <div className="split" />
            <div
              className={'tab' + (step === 4 ? ' active' : '')}
              onClick={() => this.setState({ prevStep: step, step: 4 })}
            >
              <span className="notTablet">STEP 4.</span> Submit for Quotation
            </div>
          </div>
          <div className="buttonsBlock">
            <Button
              className="button back"
              onClick={step > 1 ? this.handlePrev : null}
              // style={step > 1 ? {} : { pointerEvents: 'none', opacity: 0 }}
            >
              <i class="arrow left" />
              <a>BACK</a>
            </Button>
            {forExport && (
              <Button className="button" onClick={() => this.toggleModal(true)}>
                <a>EXPORT</a>
              </Button>
            )}
            <Button className="button resize onlyMobile" onClick={() => this.toggleRatios()}>
              {isRatio50 && <a>⤓</a>}
              {!isRatio50 && <a>⤒</a>}
            </Button>
            <Button
              className="button next"
              onClick={step < 4 ? this.handleNext : null}
              // style={step < 4 ? {} : { visibility: 'hidden', opacity: 0 }}
            >
              <a>NEXT STEP</a>
              <i class="arrow right" />
            </Button>
          </div>
          {step === 4 && (
            <Button
              className="button submitButton onlyMobile"
              wide
              isLoading={emailIsSending}
              onClick={(e) => {
                e.preventDefault()
                if (this.submit) {
                  gaTrackSubmit()
                  this.submit.click()
                }
              }}
            >
              <a>Submit for Price Quote</a>
            </Button>
          )}
          <ReactTooltip place="top" type="light" effect="solid" globalEventOff="click" />
        </Container>
        <Modal title="Export Tool" isVisible={isModalOpen} closeModal={() => this.toggleModal(false)}>
          <ExportTool>
            <div className="wrapper">
              <div className="texts">
                <div className="label">Chair ID url:</div>
                <div className="text">
                  <div
                    className="prop"
                    style={{
                      maxWidth: '350px',
                      overflow: 'auto',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <div className="propValue">
                      {window.location.protocol +
                        '//' +
                        window.location.host +
                        window.location.pathname +
                        this.generateQueryParams(this.state.parts, false)}
                    </div>
                  </div>
                </div>
                <div className="text">
                  {[
                    'model',
                    'material',
                    'color',
                    'stitching',
                    'piping',
                    'woodtrim',
                    'wood_finish',
                    'metal_finish',
                    'diamond_quilting',
                    'cupholder',
                  ].map(
                    (name) =>
                      this.getProperties()[name] && (
                        <div className="prop">
                          <div className="propName">{name.split('_').join(' ').toUpperCase()}:</div>
                          <div className="propValue">{this.getProperties()[name]}</div>
                        </div>
                      )
                  )}
                </div>
              </div>
              <div className="images">
                <div className="inputs">
                  <Input label="Screenshots Width" contextThis={this} name="width" parentObj="screenshots" />
                  <Input label="Screenshots Height" contextThis={this} name="height" parentObj="screenshots" />
                  <Button className="button" isLoading={screenshots.inProcess} onClick={this.makeScreenshots}>
                    <a>Get screenshots</a>
                  </Button>
                </div>
                <div className="screenshots">
                  {screenshots.images.map((img) => (
                    <img src={img} alt="" />
                  ))}
                </div>
              </div>
            </div>
          </ExportTool>
        </Modal>
        {/* {showConfirmationPage && (
          <ConfirmationPage
            goBack={() => this.setState({ showConfirmationPage: false })}
          />
        )} */}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </React.Fragment>
    )
  }

  handlePrev = () => {
    const { step } = this.state
    const newStep = step - 1
    updateStepQuery(newStep)
    this.setState({ prevStep: step, step: newStep })
  }

  handleNext = () => {
    const { step } = this.state
    const newStep = step + 1
    gaTrackNext(newStep)
    updateStepQuery(newStep)
    this.setState({ prevStep: step, step: newStep })
  }
}

Configurator.propTypes = {}

export default Configurator
