// import { connect } from 'react-redux';

import Configurator from './Configurator.component'

// const mapStateToProps = state => ({});

// function mapDispatchToProps(dispatch) {
//   return {
//     editThisFunction() {},
//   };
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(Configurator);
export default Configurator
