import { srgbToLinear, hexToRgb } from './colorTools.helper'

const changeStitchesColor = (overwriteChannels, hex) => {
  return new Promise((res) => {
    if (!overwriteChannels) return res()

    const defaultMaterial = { ...window.api._getMaterialByName('stitches_mat') }
    const linearColor = srgbToLinear(hexToRgb(hex))
    defaultMaterial.channels = overwriteChannels(defaultMaterial.channels, linearColor)

    return window.api.setMaterial(defaultMaterial, res)
  })
}

export { changeStitchesColor }
