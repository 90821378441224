const leathersGrouped = {
  'Silk Leather': {
    tooltip:
      'Our most popular material - an extremely buttery soft, breathable, and flexible synthetic material that has the visual and spill resistant qualities of leather. This premium material carries a 10% surcharge over Valentino Leather.',
    sampleImage: require('../img/silk.png'),
    overwriteChannels: (channels, linearColor, replaceTexture = true) => {
      const normalTexture = (window.api.textures.filter((t) => t.name === 'silk_leather_normal.jpg') || [])[0]
      const cavityTexture = (window.api.textures.filter((t) => t.name === 'silk_leather_cavity.jpg') || [])[0]

      if (replaceTexture) {
        if (normalTexture) {
          channels.NormalMap.texture = { ...(channels.NormalMap.texture || {}), uid: normalTexture.uid }
        }
      }
      if (cavityTexture) {
        channels.CavityPBR.texture = { ...(channels.CavityPBR.texture || {}), uid: cavityTexture.uid }
      }
      channels.SpecularF0.factor = 0.2
      channels.RoughnessPBR.factor = 0.6
      channels.BumpMap.enable = false
      channels.NormalMap.enable = true
      channels.CavityPBR.enable = true
      channels.MetalnessPBR.enable = false

      channels.AlbedoPBR.color = linearColor
      channels.DiffusePBR.color = linearColor
      channels.DiffuseColor.color = linearColor

      return channels
    },
    colors: {
      Ash: '#47433e',
      Aztec: '#61331a',
      'Beet Root': '#431e1f',
      'Black Onyx': '#090909',
      Cabernet: '#341F27',
      'French Vanilla': '#DFD7C0',
      Caramel: '#57301d',
      'Desert Clay': '#84654f',
      Indigo: '#1a1b2d',
      Truffle: '#453329',
      'Evening Blue': '#080e20',
      'Pompeian Red': '#5b0401',
      Shiitake: '#413735',
      Peppercorn: '#1e1513',
    },
  },
  'Valentino Leather': {
    tooltip:
      'Our standard, natural, top grain leather is sourced from Italy, and is of the highest quality our customers expect.',
    sampleImage: require('../img/valentino.png'),
    overwriteChannels: (channels, linearColor, replaceTexture = true) => {
      const normalTexture = (window.api.textures.filter((t) => t.name === 'valentino_leather_normal.jpg') || [])[0]
      const cavityTexture = (window.api.textures.filter((t) => t.name === 'valentino_leather_cavity.jpg') || [])[0]

      if (replaceTexture) {
        if (normalTexture) {
          channels.NormalMap.texture = { ...(channels.NormalMap.texture || {}), uid: normalTexture.uid }
        }
      }
      if (cavityTexture) {
        channels.CavityPBR.texture = { ...(channels.CavityPBR.texture || {}), uid: cavityTexture.uid }
      }

      channels.SpecularF0.factor = 0.2
      channels.RoughnessPBR.factor = 0.62
      channels.BumpMap.enable = false
      channels.NormalMap.enable = true
      channels.CavityPBR.enable = true
      channels.MetalnessPBR.enable = false

      channels.AlbedoPBR.color = linearColor
      channels.DiffusePBR.color = linearColor
      channels.DiffuseColor.color = linearColor

      return channels
    },
    colors: {
      Charcoal: '#404143',
      'Fire Engine Red': '#6d0907',
      Hazelnut: '#463528',
      'Mist Blue': '#384447',
      Mocha: '#84654f',
      Cream: '#978264',
      'Dark Cherry': '#431e1f',
      Navy: '#1a1b2d',
      Espresso: '#1e1513',
      'Smoke Grey': '#47433e',
    },
  },
  Cinesuede: {
    tooltip:
      'An extremely soft, high quality suede-textured fabric that provides a warm and cozy, true-to-theater cinematic experience.',
    sampleImage: require('../img/cinesuede.png'),
    overwriteChannels: (channels, linearColor, replaceTexture = true) => {
      const normalTexture = (window.api.textures.filter((t) => t.name === 'cinesuede_normal.jpg') || [])[0]
      // const bumpTexture = (window.api.textures.filter((t) => t.name === 'leather_diff_levels.jpg') || [])[0]
      const metalnessTexture = (window.api.textures.filter((t) => t.name === 'leather_diff_levels.jpg') || [])[0]

      if (replaceTexture) {
        if (normalTexture) {
          channels.NormalMap.texture = { ...(channels.NormalMap.texture || {}), uid: normalTexture.uid }
        }
      }
      // if (bumpTexture) {
      //   channels.BumpMap.texture = { ...(channels.BumpMap.texture || {}), uid: bumpTexture.uid }
      // }
      if (metalnessTexture) {
        channels.MetalnessPBR.texture = { ...(channels.MetalnessPBR.texture || {}), uid: metalnessTexture.uid }
      }
      channels.SpecularF0.factor = 0.6
      channels.RoughnessPBR.factor = 0.85
      // channels.BumpMap.enable = true
      channels.CavityPBR.enable = false
      channels.NormalMap.enable = true
      channels.MetalnessPBR.enable = true
      channels.MetalnessPBR.factor = 0.38

      channels.AlbedoPBR.color = linearColor
      channels.DiffusePBR.color = linearColor
      channels.DiffuseColor.color = linearColor

      return channels
    },
    colors: {
      Berry: '#5D001E',
      Bordeaux: '#330f0f',
      'Dark Chocolate': '#21120d',
      Navy: '#242c2f',
      Graphite: '#585752',
      'Jet Black': '#0e0e0e',
      'Deep Purple': '#1a1b2d',
      'Theater Red': '#7B0016',
      Saddle: '#372116',
      Taupe: '#867058',
    },
  },
}

const leathers = Object.keys(leathersGrouped).map((leatherName) =>
  Object.keys(leathersGrouped[leatherName].colors).map((colorName) => ({
    leatherName,
    colorName,
    hex: leathersGrouped[leatherName].colors[colorName],
    overwriteChannels: leathersGrouped[leatherName].overwriteChannels,
    sampleImageUrl: leathersGrouped[leatherName].sampleImage,
    tooltip: leathersGrouped[leatherName].tooltip,
  }))
)

// console.log(leathers)

export default leathers
