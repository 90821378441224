import Email from '../vendors/smtp-tapmod'

export const sendEmail = (data, cb) => {
  const SmtpClientID = 'elitehts'

  const msg = {
    Profile: "elite-hts",
    Fields: {
      ...data
    }
  }

  const msg_manager = { ...JSON.parse(JSON.stringify(msg)), To: 'sales@elitehts.com', Reply_to: data.email }
  const msg_customer = { ...JSON.parse(JSON.stringify(msg)), To: data.email }
  msg_customer.Fields.isCustomer = true

  Email.send(
    msg_manager,
    (message) => {
      if (message === 'OK') return cb(message, 'success')
      return cb(message, 'error')
    }
  )

  Email.send(
    msg_customer,
    () => {}
  )
}
