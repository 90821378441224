const hideAllLayouts = (rows = ['1', '2']) => {
  const nodesToHide = [
    'single',
    'loveseat',
    'straight_row_of_two',
    'curved_row_of_two',
    'three_seat_sofa',
    'straight_row_of_three',
    'curved_row_of_three',
    'double_loveseat',
    'curved_double_loveseat',
    'straight_flanked_loveseat',
    'curved_flanked_loveseat',
    'straight_row_of_four',
    'curved_row_of_four',
    'straight_row_of_five',
    'curved_row_of_five',
    'captains_chair_double_loveseat',
  ]
  const promisses = []
  rows.forEach((n) => {
    nodesToHide.forEach((node) => {
      if (window.api2) promisses.push(window.api2.node_hide(node + n))
    })
  })
  return Promise.all(promisses)
}

const showOneLayout = (nodeName) =>
  window.api2 &&
  hideAllLayouts().then(() => {
    return window.api2.node_show(nodeName)
  })

const showLayouts = (nodeNames) => {
  const promisses = [hideAllLayouts()]
  nodeNames.forEach((nodeName, k) => {
    if (window.api2) promisses.push(window.api2.node_show(nodeName))
  })
  return Promise.all(promisses)
}

const showBackRow = (nodeName = 'curved_double_loveseat') => {
  const promisses = []
  if (window.api2) promisses.push(window.api2.node_show(nodeName + '2'))

  return Promise.all(promisses)
}

const hideBackRow = () => {
  const promisses = [hideAllLayouts(['2'])]

  return Promise.all(promisses)
}

export { showOneLayout, showLayouts, showBackRow, hideBackRow }
