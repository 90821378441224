import styled from 'styled-components'

export const Container = styled.div`
  & {
    /* height: 100%;
    width: 100%; */
  }
  iframe {
    height: 100%;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
  }
  iframe {
    border: none;
  }
  &.minimized {
    iframe {
      width: 400px;
      max-width: 30vw;
      max-height: 35vh;
      height: 250px;
      top: 70px;
      right: 23%;
      left: auto;
      border: 1px solid #77674a;
      z-index: -1;
    }
    .loader {
      position: absolute;
      width: 400px;
      max-width: 30vw;
      max-height: 35vh;
      height: 250px;
      top: 70px;
      right: 23%;
      left: auto;
      border: 1px solid #77674a;
      z-index: 20;
      color: #77674a;
      font-weight: bold;
      background: rgba(239,239,239,1)!important;
      display: flex;
      flex-flow: column;
      align-items: center;
      div {
        padding-top: 50px
      }
      .spinner {
        animation: rotate 2s linear infinite;
        margin-top: 25px;
        width: 35px;
        height: 35px;

        & .path {
          stroke: #77674a;
          stroke-linecap: round;
          animation: dash 1.5s ease-in-out infinite;
        }
      }
    }
  }
  &.hidden {
    iframe {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s 0.9s ease-in-out;
    }
  }
  &.shown {
    iframe {
      visibility: visible;
      opacity: 1;
      z-index: 1;
      transition: opacity 0.3s 0.9s ease-in-out;
    }
  }
  &.layoutFrame {
    iframe {
      position: absolute;
      background-color: rgba(239, 239, 239, 0.6);
    }
  }
  @media only screen and (max-width: 772px) {
    iframe {
      height: calc(50% - 40px);
    }
    &.minimized {
      iframe {
        width: 47vw;
        height: 30vw;
        max-width: unset;
        max-height: unset;
        /* width: 140px;
        height: 90px; */
        top: 15px;
        right: 15px;
        left: auto;
      }
      .loader {
        width: 47vw;
        height: 30vw;
        max-width: unset;
        max-height: unset;
        /* width: 140px;
        height: 90px; */
        top: 15px;
        right: 15px;
        left: auto;
        font-size: .7rem;
        div  {
          padding-top: 10px
        }
        .spinner {
          margin-top: 10px;
          width: 15px;
          height: 15px;
        }
      }
 
    }
  }
  @media only screen and (max-height: 400px) and (max-width: 772px) {
    &.minimized {
      iframe {
        width: 140px;
        height: 90px;
        top: 10px;
        right: 10px;
        left: auto;
      }
      .loader {
        width: 140px;
        height: 90px;
        top: 10px;
        right: 10px;
        left: auto;
      }
    }
  }
`

export const style = {}

export default style
