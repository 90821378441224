const hideAllArmNodes = () => {
  const nodesToHide = ['arms_B', 'arms_C', 'arms_D', 'arms_F', 'arms_J', 'arms_L', 'arms_S', 'arms_I', 'arms_N']
  const promisses = []
  nodesToHide.forEach((node) => {
    promisses.push(window.api.node_hide(node))
  })
  return Promise.all(promisses)
}

const showOneArm = (nodeName) => {
  return hideAllArmNodes().then(() => {
    return window.api.node_show(nodeName)
  })
}

export { showOneArm }
