import styled from 'styled-components'

export const Container = styled.div`
  /* Button Styles */
  &.loading {
    pointer-events: none;
  }
  .button {
    display: inline-flex;
    height: 40px;
    width: 150px;
    border: 2px solid #000;
    margin: 20px 20px 20px 20px;
    color: rgba(239, 239, 239, 1);
    /* color: #b99777; */
    text-transform: uppercase;
    text-decoration: none;
    font-size: 0.8em;
    letter-spacing: 1.5px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-color: rgba(0, 0, 0, 1);
    /* background-color: rgba(239, 239, 239, 0.8); */
  }
  a,
  i {
    /* color: #b99777; */
    /* border-color: #b99777; */
    color: rgba(239, 239, 239, 1);
    border-color: rgba(239, 239, 239, 1);
    text-decoration: none;
    letter-spacing: 1px;
    font-weight: 700;
  }
  /* Sixth Button */

  .button-6 {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  .button-6 a,
  .button-6 i {
    position: relative;
    transition: all 0.45s ease-Out;
  }

  .spin {
    width: 0;
    height: 0;
    opacity: 0;
    left: 50%;
    top: 50%;
    transform: rotate(0deg);
    background: none;
    position: absolute;
    transition: all 0.5s ease-Out;
  }

  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 2.1em;
    height: 2.1em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  .loader {
    color: #b99777;
    font-size: 6px;
    line-height: 40px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
    margin-top: -25px;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.2em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.2em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.2em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.2em 0 0;
    }
  }
  @media only screen and (max-width: 772px) {
    /* .button-6:hover:not(:active) .spin {
      width: 100%;
      padding-bottom: 100%;
      height: unset;
      top: unset;
      left: unset;
    } */
    .button-6:hover:not(:active) {
      opacity: 0.9;
    }
    .loader {
      color: rgb(236, 235, 233);
    }
  }
  @media only screen and (min-width: 773px) {
    &.loading .button-6:not(:active) .spin,
    .button-6:hover:not(:active) .spin {
      width: 200%;
      height: 500%;
      opacity: 1;
      left: -70px;
      top: -70px;
      background: rgba(239, 239, 239, 1);
      /* background: #b99777; */
      transform: rotate(80deg);
    }

    &.loading .button-6:not(:active) a,
    .button-6:hover:not(:active) a {
      /* color: rgba(239, 239, 239, 1); */
      color: #000;
    }
    &.loading .button-6:not(:active) i,
    .button-6:hover:not(:active) i {
      /* border-color: rgba(239, 239, 239, 1); */
      border-color: #000;
    }

    .button.wide {
      width: 220px;
      max-width: 100%;
      margin: 20px auto;
      &:hover .spin {
        height: 524%;
        left: -114px;
        top: -110px;
      }
    }
    &.loading .button.wide .spin {
      height: 524%;
      left: -114px;
      top: -110px;
    }
  }
`

export const style = {}

export default style
