import { srgbToLinear, hexToRgb } from './colorTools.helper'
const changeWoodtrimTexture = (overwriteChannels, hex) => {
  return new Promise((res) => {
    // const texture = (window.api.textures.filter((t) => t.name === textureName) || [])[0]
    // if (!texture) return res()

    const defaultMaterial = { ...window.api._getMaterialByName('wood_mat') }
    const linearColor = srgbToLinear(hexToRgb(hex))
    // console.log(linearColor)
    defaultMaterial.channels = overwriteChannels(defaultMaterial.channels, linearColor)

    return window.api.setMaterial(defaultMaterial, res)
  })
}

export { changeWoodtrimTexture }
