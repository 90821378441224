const woodFinishes = [
  {
    name: 'Matte',
    class: 'matte',
    buttonImage: require(`../img/wood_matte.png`),
    woodImage: require(`../img/wood.png`),
    overwriteChannels: (channels) => {
      channels.SpecularF0.factor = 0.5
      channels.RoughnessPBR.factor = 0.4
      channels.ClearCoat.enable = false
      return channels
    },
  },
  {
    name: 'Satin',
    class: 'satin',
    buttonImage: require(`../img/wood_satin.png`),
    woodImage: require(`../img/wood.png`),
    overwriteChannels: (channels) => {
      channels.SpecularF0.factor = 0.7
      channels.RoughnessPBR.factor = 0.24
      channels.ClearCoat.enable = false
      return channels
    },
  },
  {
    name: 'High Gloss',
    class: 'gloss',
    buttonImage: require(`../img/wood_gloss.png`),
    woodImage: require(`../img/wood.png`),
    overwriteChannels: (channels) => {
      channels.SpecularF0.factor = 0.5
      channels.RoughnessPBR.factor = 0.4
      channels.ClearCoat.enable = true
      return channels
    },
  },
]

export default woodFinishes
