import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from 'containers/App'
import * as serviceWorker from './serviceWorker'


// function preventPullToRefresh(element) {
//   var prevent = false

//   document.querySelector(element).addEventListener('touchstart', function(e) {
//     if (e.touches.length !== 1) {
//       return
//     }

//     var scrollY = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop
//     prevent = scrollY === 0
//   })

//   document.querySelector(element).addEventListener('touchmove', function(e) {
//     if (prevent) {
//       prevent = false
//       e.preventDefault()
//     }
//   })
// }

// preventPullToRefresh('body') // pass #id or html tag into the method

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
