import React from 'react'

import { style, Container } from './Button.style'

const Button = ({ className, onClick = () => {}, children, style, isLoading, wide }) => {
  return (
    <Container className={`${className} ${isLoading ? 'loading' : ''}`} style={style}>
      <div className={'button button-6' + (wide ? ' wide' : '')} onClick={onClick}>
        <div className="spin" />
        {!isLoading && (children ? children : <a>Let's Go!</a>)}
        {isLoading && <div class="loader">Sending...</div>}
      </div>
    </Container>
  )
}

export default Button
