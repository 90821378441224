import { srgbToLinear, hexToRgb } from './colorTools.helper'

const changeColor = (overwriteChannels, hex) => {
  return new Promise((res) => {
    if (!overwriteChannels) return res()
    const material = 'piping_mat'
    const defaultMaterial = { ...window.api._getMaterialByName(material) }
    const linearColor = srgbToLinear(hexToRgb(hex))
    defaultMaterial.channels = overwriteChannels(defaultMaterial.channels, linearColor)

    return window.api.setMaterial(defaultMaterial, res)
  })
}

const show = () => {
  return new Promise((res) => {
    const mat = window.api._getMaterialByName('piping_mat')
    mat.channels.Opacity.factor = 1
    window.api.setMaterial(mat, res)
  })
}

const hide = () => {
  return new Promise((res) => {
    const mat = window.api._getMaterialByName('piping_mat')
    mat.channels.Opacity.factor = 0
    window.api.setMaterial(mat, res)
  })
}

export { changeColor, show, hide }
