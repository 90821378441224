import styled from 'styled-components'

export const Container = styled.div`
  z-index: 100;
  position: flex;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  * {
    transition: all 0.2s ease-in-out;
  }
  .modalContent {
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 101;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: max-content;
    max-height: 80%;
    max-width: 80%;
    overflow: hidden;
    padding: 20px;
    width: 80vw;
    &.slim {
      @media only screen and (min-width: 773px) {
        width: 30vw;
      }
    }
    .closeCross {
      cursor: pointer;
      opacity: 1;
      &:hover {
        opacity: 0.7;
      }
      position: absolute;
      top: 0;
      right: 0;
      font-size: 20px;
      padding: 15px;
      line-height: 15px;
      color: #666;
      font-weight: 100;
    }
    .title {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      font-size: 20px;
      padding: 15px;
      line-height: 15px;
      text-transform: uppercase;
      color: #666;
      font-weight: 100;
    }
  }

  .inputs {
  }
`

export const style = {}

export default style
