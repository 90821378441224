import styled from 'styled-components'

export const Container = styled.div`
  height: 54px;
  width: 100%;
  box-sizing: border-box;
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 100;
  padding: 0 24px;
  .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 101;
    img {
      max-height: 50px;
    }
  }
  .mobileBurger {
  }
  .sider {
  }
  .item {
    padding: 0 8px;
    text-transform: uppercase;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    a {
      text-decoration: none;
      color: #fff;
    }
    position: relative;
    &:after {
      content: '';
      width: 0%;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      height: 3px;
      background-color: #b99777;
      z-index: 100;
      transition: width 0.3s ease-in-out;
    }
    &:hover {
      &:after {
        width: 80%;
        width: calc(100% - 16px);
      }
    }
  }

  @media only screen and (min-width: 1141px) {
    .mobileBurger {
      display: none;
    }
    .sider {
      &,
      .menu-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      width: 100%;
      box-sizing: border-box;
    }
  }
  @media only screen and (max-width: 1140px) {
    .mobileBurger {
      display: block;
    }
    .sider {
      display: none;
    }

    a {
      text-decoration: none;
      color: #fff;

      transition: color 0.3s ease;
    }

    a:hover {
      color: tomato;
    }

    #menuToggle {
      display: block;
      position: relative;
      z-index: 1;
      -webkit-user-select: none;
      user-select: none;
    }

    #menuToggle input {
      display: block;
      width: 18px;
      height: 18px;
      position: absolute;
      top: -7px;
      left: -5px;

      cursor: pointer;

      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */

      -webkit-touch-callout: none;
    }

    #menuToggle span {
      display: block;
      width: 18px;
      height: 2px;
      margin-bottom: 3px;
      position: relative;

      background: #fff;
      border-radius: 3px;

      z-index: 1;

      transform-origin: 4px 0px;

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        opacity 0.55s ease;
    }

    #menuToggle span:first-child {
      transform-origin: 0% 0%;
    }

    #menuToggle span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }

    #menuToggle input:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(0, -1px);
      background: #fff;
    }

    #menuToggle input:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    #menuToggle input:checked ~ span:nth-last-child(2) {
      transform: rotate(-45deg) translate(2px, 0px);
    }

    #menu {
      position: absolute;
      width: 100vw;
      height: 100vh;
      margin: -41px -24px;
      padding: 50px 0;
      box-sizing: border-box;
      padding-top: 125px;

      background: #000;
      list-style-type: none;
      -webkit-font-smoothing: antialiased;

      transform-origin: 0% 0%;
      transform: translate(-100%, 0);

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }

    #menu li {
      padding: 10px 0;
      font-size: 22px;
    }

    #menuToggle input:checked ~ ul {
      transform: none;
    }
  }
`

export const style = {}

export default style
