const stitches = [
  {
    name: 'Silver',
    buttonImg: require('../img/thread.png'),
    hex: '#7E7E7E',
    overwriteChannels: (channels, linearColor) => {
      channels.AlbedoPBR.color = linearColor
      return channels
    },
  },
  {
    name: 'Black',
    buttonImg: require('../img/thread.png'),
    hex: '#242424',
    overwriteChannels: (channels, linearColor) => {
      channels.AlbedoPBR.color = linearColor
      return channels
    },
  },
  {
    name: 'Red',
    buttonImg: require('../img/thread.png'),
    hex: '#973535',
    overwriteChannels: (channels, linearColor) => {
      channels.AlbedoPBR.color = linearColor
      return channels
    },
  },
  {
    name: 'Charcoal',
    buttonImg: require('../img/thread.png'),
    hex: '#545454',
    overwriteChannels: (channels, linearColor) => {
      channels.AlbedoPBR.color = linearColor
      return channels
    },
  },
  {
    name: 'Gold',
    buttonImg: require('../img/thread.png'),
    hex: '#645712',
    overwriteChannels: (channels, linearColor) => {
      channels.AlbedoPBR.color = linearColor
      return channels
    },
  },
  {
    name: 'Chocolate',
    buttonImg: require('../img/thread.png'),
    hex: '#443014',
    overwriteChannels: (channels, linearColor) => {
      channels.AlbedoPBR.color = linearColor
      return channels
    },
  },
  {
    name: 'Mocha',
    buttonImg: require('../img/thread.png'),
    hex: '#6B5E4D',
    overwriteChannels: (channels, linearColor) => {
      channels.AlbedoPBR.color = linearColor
      return channels
    },
  },
  {
    name: 'Claret',
    buttonImg: require('../img/thread.png'),
    hex: '#624D55',
    overwriteChannels: (channels, linearColor) => {
      channels.AlbedoPBR.color = linearColor
      return channels
    },
  },
  {
    name: 'Navy',
    buttonImg: require('../img/thread.png'),
    hex: '#404166',
    overwriteChannels: (channels, linearColor) => {
      channels.AlbedoPBR.color = linearColor
      return channels
    },
  },
  {
    name: 'White',
    buttonImg: require('../img/thread.png'),
    hex: '#B4B4B4',
    overwriteChannels: (channels, linearColor) => {
      channels.AlbedoPBR.color = linearColor
      return channels
    },
  },
]

export default stitches
