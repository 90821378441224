export const gaTrackNext = step => {
  try {
    // eslint-disable-next-line no-undef
    ga('send', {
      hitType: 'event',
      eventCategory: 'Next',
      eventAction: 'click',
      eventLabel: `Next ${step + 1}`
    });
  } catch (error) {}
};

export const gaTrackSubmit = step => {
  try {
    // eslint-disable-next-line no-undef
    ga('send', {
      hitType: 'event',
      eventCategory: 'Submit',
      eventAction: 'submit',
      eventLabel: `Conversion submit`
    });
  } catch (error) {}
};

export const getStepFromQuery = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('step');
};

export const updateStepQuery = step => {
  var searchParams = new URLSearchParams(window.location.search);
  searchParams.set('step', step);
  var newRelativePathQuery =
    window.location.pathname + '?' + searchParams.toString();
  window.history.pushState(null, '', newRelativePathQuery);
};
