const woods = {
  white_oak: { 
    name: 'White Oak', 
    textureName: 'wood.png',
    hex: '#EDB6A7' 
  },
  chestnut: { 
    name: 'Chestnut', 
    textureName: 'wood.png',
    hex: '#E09563' 
  },
  october_oak: { 
    name: 'October Oak', 
    textureName: 'wood.png',
    hex: '#E28662' 
  },
  cinnamon: { 
    name: 'Cinnamon', 
    textureName: 'wood.png',
    hex: '#A46952' 
  },
  black_walnut: { 
    name: 'Black Walnut', 
    textureName: 'wood.png',
    hex: '#805942' 
  },
  mahogany: { 
    name: 'Mahogany', 
    textureName: 'wood.png',
    hex: '#884848' 
  },
  charcoal: { 
    name: 'Charcoal', 
    textureName: 'wood.png',
    hex: '#725C4F' 
  },
  piano_black: { 
    name: 'Piano Black', 
    textureName: 'wood.png',
    hex: '#3C3C3C' 
  },
}

const woodtrims = Object.keys(woods).map((wood) => ({
  name: woods[wood].name,
  hex: woods[wood].hex,
  textureName: woods[wood].textureName,
  url: require(`../img/${woods[wood].textureName}`),
  // url: require(`../img/wood_${wood}.jpg`),
  overwriteChannels: (channels, linearColor) => {
    // channels.AlbedoPBR.texture = { ...(channels.AlbedoPBR.texture || {}), uid: textureID }
    channels.AlbedoPBR.color = linearColor

    return channels
  },
}))

export default woodtrims
