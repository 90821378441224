import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { style, Container } from './Modal.style'

class Modal extends Component {
  render() {
    const { children, closeModal, isVisible, title, slimContent } = this.props
    return isVisible ? (
      <Container>
        <div className={'modalContent' + (slimContent ? ' slim' : '')}>
          <div className="closeCross" onClick={closeModal}>
            ✕
          </div>
          <div className="title">{title}</div>
          {children}
        </div>
      </Container>
    ) : (
      <span></span>
    )
  }
}

Modal.propTypes = {}

export default Modal
